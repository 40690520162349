.report-section {
  .client-report-card {
    padding: 30px 40px 28px;
    border-radius: 20px;
    border: none;
    margin-bottom: 18px;
    @media (max-width:520px) {
      padding: 30px 15px 28px;

      
    }
    .report-card-header {
      margin-bottom: 30px;
      @media (max-width:520px) {
        display: block!important;
        
      }
      .client-name {
        font-size: 30px;
        font-weight: 600;
        @media (max-width:520px) {
          font-size: 1.3em;
          // display: block!important;
          
        }
      }
      .report-link {
        background: #2062f4;
        border-radius: 10px;
        padding: 10px 19px;
        font-size: 14px;
        text-decoration: none;
        color: #fff;
      }
    }
    .avg-engagement-card,
    .links-clicked,
    .likes {
      border: none;
      height: 100%;
      padding: 25px 52px;
      border-radius: 23px;
      color: #fff;
      margin-bottom: 12px;
      .card-title {
        font-size: 16px;
        font-weight: 600;
        // @media (max-width: 1440px) {
        //   font-size: 12px;
        //   font-weight: 600;
        // }
      }
      p {
        font-size: 20px;
        font-weight: 200;
      }
    }
    .avg-engagement-card {
      padding: 32px 0 32px 35px;

      background: #2062f4 !important;
    }
    .links-clicked {
      background: #49a8f8 !important;
    }
    .likes {
      background: linear-gradient(345deg, #7337fd, #2062f4) !important;
    }
  }
}

.report-details {
  .report-details-header {
    margin-bottom: 60px;
    h1 {
      font-size: 18px;
    }
    button {
      background: #2062f4;
      border-radius: 10px;
      padding: 10px 30px;
      border: none;
      box-shadow: none;

      &:focus{
        box-shadow: none;
        outline: none !important;
      }
    }
   
  }

  .report-details-card {
    padding: 18px 35px !important;
    border: none;
    border-radius: 20px;
  }

  .nav {
    @media (max-width:600px) {
      display: block !important;
      
    }
    .nav-item {
      margin-bottom: 12px !important;
      padding: 0 !important;
      margin-right: 69px !important;
      font-size: 14px;
      font-weight: 400;
  
      a {
        text-decoration: none !important;
        color: #000 !important;
        &.active-tab {
          color: #49a8f8 !important;
          // padding-left: 10px;
          position: relative;
          &::before {
            content: " ";
            background: #49a8f8;
            border-radius: 10px;
            position: absolute;
            margin: 0 auto;
            width: 100%;
            height: 2px;
            // left: 36px;
            bottom: 0;
            top: 32px;
            transform: initial;
            @media (max-width:600px) {
              background: none;
              
            }
          }
        }
      }
    }
  
  }
  
  .avg-engagement-card,
  .links-clicked,
  .likes {
    border: none;
    height: 100%;
    padding: 32px 12px;
    border-radius: 23px;
    color: #fff;
    margin-bottom: 12px;
    .card-header-wrapper {
      padding: 0 20px;
    }
    .card-title {
      font-size: 16px;
      font-weight: 600;
      // @media (max-width: 1440px) {
      //   font-size: 12px;
      //   font-weight: 600;
      // }
    }
    p {
      font-size: 20px;
      font-weight: 200;
    }
  }

  .avg-engagement-card {
    // padding: 32px 0 32px 35px;

    background: #2062f4;
    .chart-container {
      // background: #fff;
      padding: 0 12px;
      opacity: 0.3;
      height: 70%;
    }
  }
  .links-clicked {
    background: #49a8f8;
  }
  .likes {
    background: linear-gradient(345deg, #7337fd, #2062f4);
  }
  .chart-container {
    margin: 0 auto;
    width: 100%;
    text-align: center;
  }
  .impression-card,
  .followers-card,
  .tweet-card,
  .top-3-tweet-card {
    border: 1px solid #d3dce6;
    border-radius: 20px;
    padding: 18px;

    h3 {
      text-align: center;
      font-size: 16px;
      font-weight: 600;
    }
    p {
      font-size: 20px;
      font-weight: 300;
    }
  }
  .top-3-tweet-card {
    h3 {
      text-align: left !important;
    }
  }
  .progress-count {
    font-size: 14px;
    margin-bottom: 0;
  }
  .barContainer {
    background: transparent;
  }
  .impression-chart-card {
    border: 1px solid #d3dce6;
    border-radius: 20px;
    padding: 20px;
    h3 {
      font-weight: 600;
      font-size: 16px;
    }
  }

  .top-tweet-card,
  .tweet-text-card {
    padding: 25px;
    background: #e8f7ff;
    border: none;

    border-radius: 20px;
    h3 {
      font-size: 16px;
      margin-bottom: 20px;
    }
    .top-tweet-wrapper {
      border-radius: 10px;
      p {
        padding: 0 12px;
        margin-top: 20px;
        font-size: 12px;
      }
    }
  }

  .tweet-text-card {
    p {
      color: #2062f4;
      font-size: 12px;
    }
  }
  .top-3-tweet-card{
    padding: 30px;
  }
}
.export-report-wrapper {
  text-align: center;
  margin: 180px 0px;
  h1 {
    font-size: 300;
    margin-bottom: 18px;
  }
  p {
    margin: auto;
    width: 70%;
    font-size: 13px;
  }
  button {
    background: #2062f4;
    border-radius: 16px;
    border: none;
    margin: 36px auto auto;
    width: 80%;
    outline: none;

  }
}
