.container {
    padding: 37px 48px 32px;
    background-color: rgba(224, 224, 224,0.2);
    border: 1px solid rgba(192, 204, 218, 0.2);
    border-radius: 20px;
    position: relative;
    margin-bottom: 20px;
    margin-right: 52px;
    width: 237px;
    height: 252px;
}

.tick {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
}

.tick img {
    width: 60px;
    height: 60px;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.logo img {
    max-width: 140px;
    max-height: 94px;
}

.logo img[alt='paystack'] {
    width: 51px;
}

.nameButton {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.name {
    margin-bottom: 7px;
}

.button {
    border: none;
    background-color: #E8F7FF;
    border-radius: 8px;
    padding: 10px;

    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 1.25px;
    color: #0B6BFF;
    cursor: pointer;
    width: 125px;
}
