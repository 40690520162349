.content-ideas {
  .date-select-container {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    border-bottom: 1px solid #e5ecf2;
    padding-bottom: 1rem;
    margin-bottom: 2.4rem;
    .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 30px;
      font-size: 18px;
      font-weight: 500;
      button {
        border: none;
        background-color: transparent;
        color: #2062f4;
      }
      p {
        margin-bottom: 0;
        width: 200px;
        text-align: center;
      }
    }
  }
  .content-days-container {
    border-radius: 15px;
    box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.05);
    padding: 3rem 8rem 2rem 8rem;
    height: calc(100vh - 238px);

    @media (max-width: 576px) {
      padding: 1rem;
    }
    .available-days {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 5rem;
      row-gap: 1rem;
      width: 100%;
      // height: 100%;
      overflow-y: auto;

      @media (max-width: 576px) {
        grid-template-columns: 1fr;
      }
      .available-day {
        border-bottom: 1px solid #e5ecf2;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
        cursor: pointer;
        h4 {
          font-size: 1rem;
          white-space: no-wrap;
          margin-bottom: 0;
          font-weight: 600;
          @media (max-width: 576px) {
            font-size: 14px;
            white-space: wrap;
          }
        }
        .day-and-icon {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          img {
            border-radius: 10px;
            padding: 0.5rem;
            background: #fff;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.14);
          }
        }
      }
    }
  }
}
