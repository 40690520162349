.app-layout {
  background: #f9fafc;
}

.app-content {
  padding: 16px 33px 30px 0;
  /* margin-top: 6rem; */
  margin-left: 244px;
  min-height: 100vh;
  /* position  : absolute; */
}

.client-app-layout {
  padding: 16px 42px 0 42px;
  .topbar-right-content {
    button {
      background: #2062f4;
      border-radius: 10px;
      border: none;
      font-size: 20px;
      padding: 6px 35px;
      @media (max-width: 500px) {
        margin: auto;
        width: 100%;
        margin-top: 10px;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
}
.main-menu {
  z-index: 1031;
  position: absolute;
  display: table-cell;
  height: 100%;
  overflow: hidden;
  max-width: 212px;
  background-color: #fff;
  border-radius: 0 30px 30px 0 !important;

  transition: all 0.25s ease;



  .main-menu-content {
    // height: calc(100% - 6rem) !important;
    
    position: relative;
    margin-top:12px;
  }

  ul {
    list-style: none;
    text-decoration: unset;
    padding: 0 24px 0 0px;
  }

  @media (max-width: 1000px) {
    display: none;
  }
  &.show {
    display: block;

  }
}

.main-menu.menu-fixed {
  position: fixed;
}

.main-menu.menu-shadow {
  box-shadow: 0 0 15px 0 rgba(148, 144, 144, 0.199);
}

// .navbar-wrapper {
//   background-color: white;
//   margin: 1.3em 0;
//   width: 100%;
//   padding: 0.55em 0.3em;
//   border-radius: 6px;
//   /* position     : fixed; */
// }

/*  */

.nav-item {
  &.bottom{
  padding-bottom: 0em !important;

  }
  // margin-bottom: 0.8em !important;
  // padding: 0.5em 0.5em 0.5em 11px;
  padding-left: 0.5em;
  padding-bottom: 0.3em;
  transition: 0.1s;
  @media (max-width: 1000px) {
    margin-bottom: 0.8em !important;
    padding: unset;
    font-size: 2em;
  }

  &:hover {
    padding-left: 0.3em;
  }
}

.nav-item.activeNav {
  a {
    background: #e8f7ff !important;
    box-shadow: none !important;
    border-radius: 3px;
    color: #000 !important;

    .menu-text {
      a {
        &:first-child {
          color: linear-gradient(
            93.88deg,
            #49a8f8 6.88%,
            #0053f4 74.45%
          ) !important;
        }
      }
    }
  }
}

.navigation {
  &.fixed-bottom{
    width: fit-content !important;
  }
  a {
    text-decoration: none;
    color: black;
  }
}

.main-menu.menu-light {
  li.activeNav {
    color: black !important;
  }
  .navigation {
    li {
      .menu-text {
        // width: 170px;
        color: rgba(157, 168, 182, 1);
        font-size: 14px;
        font-weight: 300;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 10px 10px 10px 17px;
        &.bottom{
          padding: 2.5px 10px 2.5px 17px;

        }
        @media (max-width: 1000px) {
          padding: 0;
        }
      }
      .menu-title.activeNav {
        color: black;
        font-size: 15px;
        font-weight: 500;
        // background-color: none !important;
      }
      .menu-icon-odd.activeNav {
        // background: linear-gradient(93.88deg, #49a8f8 6.88%, #0053f4 74.45%);
        color: #7337fd;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .menu-icon-even.activeNav {
        // background: linear-gradient(93.88deg, #49a8f8 6.88%, #0053f4 74.45%);
        color: #0053f4;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .main-menu.menu-light {
    .navigation {
      li {
        .menu-text {
          width: 170px;
          color: rgba(157, 168, 182, 1);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}

.menu-title {
  padding: 0 0.75em;
}

.mobile-menu {
  display: none;
  margin-right: 7px;
  @media (max-width: 1000px) {
    display: block;
  }
}

.mobile-search-icon {
  display: none;
}

.navbar-brand {
  @media (min-width: 1000px) {
    margin-top: 20px;
    padding: 2em;
  }
}

@media (max-width: 1000px) {
  .main-menu ul {
    padding: 0 10px 0;
  }
  .main-menu {
    // width: 80px;
  transition: transform .25s cubic-bezier(0.165, 0.84, 0.44, 1);

    width: 210px;
    border-radius: 0 20px 20px 0 !important;
  }
  .menu-toggle-icon,
  .menu-title {
    // display: none;
  }
  .mobile-search-icon {
    display: block;
  }
  .main-menu.menu-light {
    .navigation {
      li {
        .menu-text {
          &:first-child {
            // text-align: center;
            font-size: 12px;
            /* width      : 170px; */
            overflow: hidden;
            text-overflow: unset;
            /* white-space: nowrap; */
          }
        }
      }
    }
  }
  .search-input-wrapper {
    display: none;
  }
  .menu-title {
    // display: none;
  }

  .app-content {
    padding: calc(2.2rem - 1.8rem) 0.7rem 0;
    /* margin-top: 6rem; */
    margin-left: 0;
    min-height: calc(100vh - 3rem);
    /* position  : absolute; */
  }

  .nav-item {
    &:hover {
      padding-left: 0;
    }
  }

  .nav-item.activeNav {
    a {
      padding: 0.1em 0.4em;
    }
  }

  .footer {
    padding-top: 0em;
    padding-bottom: 0.9rem;
    /* margin-top : -200px;
  position            : absolute; */
    margin-left: 90px;
    bottom: 0%;
    height: 60px;
    width: calc(100% - 90px);
  }
}

.navbar-logo {
  margin-left: -30px;
  width: 175px;
  height: 70px;
}

.activeNav-link {
  font: weight 500px;
  font-size: 24px;
}

.topbar {
  padding: 14px 55px 14px 50px;
  border-radius: 10px;
  margin-bottom: 17px;
  background: white;
  @media (max-width: 500px) {
    padding: 14px 25px 14px 20px;
  }
  .activeNav-menu {
    font-size: 22px;
    font-weight: 700;
  }
  .search-input-wrapper,
  .mobile-search-input-wrapper {
    position: relative;
    margin-right: 100px;
    font-size: 14px;
    font-weight: 700;
    .search-icon {
      position: absolute;
      top: 5px;
      left: 12px;
      color: #dfe1e6;
      // font-size: 20px;
      font-weight: 700;
    }
    .topnav-user {
      margin-left: 23px;
      // .mx-13{
      //   margin-left: 10px;
      //   margin-right: 13px;
      // }
    }
    .topbar-search-input {
      border: 0.13em solid #dfe1e6;
      font-weight: 200;
      width: 240px;
      padding: 4px 8px 4px 32px;
      border-radius: 5px;
    }
  }
  .mobile-search-input-wrapper {
    margin-right: 0 !important;
  }
  .userProfile-img {
    img {
      object-fit: cover;
      object-position: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 3px solid #2062f4;
    }
  }
}

.user-menu-modal {
  padiing: 50px 0 0;
  // position: relative;
  .user-menu-img {
    img {
      // position: absolute;
      // top:0px;
      // right:23px;
      object-fit: cover;
      object-position: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 3px solid #fff;
    }
  }
  .user-menu {
    border-radius: 5px;
    background-color: #fff;
    width: 201px;
    .user-menu-header {
      background-color: #f9fafc;
      border-radius: 5px;
      padding: 16px 24px;
    }
    .user-menu-settings {
      padding: 16px 38px;
    }
    .user-name {
      font-size: 16px;
      font-weight: 600;
      color: rgba(87, 90, 101, 1);
    }
    .user-email {
      font-size: 12px;
      font-weight: 400;
      color: rgba(157, 168, 182, 1);
    }
    .user-menu-items {
      padding: 10px 0;
      .user-menu-item {
        padding: 12px 43px 12px 16px;
        cursor: pointer;
        .user-menu-text {
          font-size: 14px;
          font-weight: 500;
          color: #575a65;
        }
        .user-menu-icon {
          margin-right: 18px;
        }
        &:hover {
          background: #f9fafc;
          .user-menu-text {
            color: rgba(32, 98, 244, 1);
          }
          .user-menu-icon {
            color: rgba(32, 98, 244, 1);
          }
        }
      }
    }
  }
}

.notification-modal {
  .notification-icon {
    padding-right: 16px;
  }
  .notifications-wrapper {
    width: 358px;
    background: #ffffff;
    border-radius: 20px;
    padding: 41px;
    @media (max-width: 450px) {
      margin-left: auto;
      width: 90%;
    }

    .notification-header {
      .notification-header-text {
        font-size: 15px;
        font-weight: 400;
        color: rgba(32, 98, 244, 1);
      }
      hr {
        color: #b6b8be;
      }
    }
    .notification-items {
      .notification-item.unread {
        background: #e8f7ff;
        border-radius: 20px;
        .notification-description {
          color: #575a65;
        }
      }
      .notification-item {
        padding: 16px 23px;
        .notification-title {
          font-weight: 600;
          font-size: 16px;
          color: #333333;
        }
        .notification-description {
          font-size: 12px;
          font-weight: 400;
          color: rgba(17, 19, 23, 1);
        }
      }
      .notification-button {
        background: transparent;
        color: #2062f4;
        border: 2px solid #2062f4;
      }
    }
  }
}
