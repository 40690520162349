.w-89 {
  width: 89% !important;
}
.active-menu {
  text-transform: capitalize;
  font-weight: 700 !important;
  font-size: 24px;

  @media (max-width: 500px) {
    font-size: 18px !important;
    font-weight: 500;
  }
}
body::-webkit-scrollbar {
  width: 1em !important;
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important ;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey !important;
  outline: 1px solid slategrey !important;
}
.react-calendar {
  border: none !important;
}
.calendar-header {
  margin-bottom: 30px;
}
.react-calendar__tile {
  font-size: 16px;
  font-weight: 400;
}
.react-calendar__navigation button {
  font-size: 20px;
  font-weight: 400;
  color: #111317;
  @media (max-width: 500px) {
    font-size: 12px;
  }
}
.react-calendar__month-view__days__day--weekend {
  color: #000 !important;
}

.react-calendar__month-view__weekdays {
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: rgba(60, 60, 67, 0.3);
  abbr {
    text-decoration: unset !important;
  }
}
.react-calendar__tile--active,
.react-calendar__tile--now {
  background: none !important;
  abbr {
    background: linear-gradient(93.88deg, #49a8f8 6.88%, #0053f4 74.45%);
    width: 27px;
    height: 27px;
    padding: 4px;
    border-radius: 50%;
    color: #fff;
  }
}

.modal {
  backdrop-filter: blur(3px) !important;
  border: none;
}
.modal-backdrop {
  background: rgba(29, 30, 44, 0.32) !important;
  // opacity:0.9em;
  backdrop-filter: blur(50px) !important;
}
.modal-content {
  border-radius: 40px !important;
  border: none !important;
}
.modal-cstm-button {
  padding-right: 8px;
  position: absolute;
  top: 32px;
  right: 32px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #c0ccda;
  color: #fff;
}

.top-right-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background: rgba(29, 30, 44, 0.32) !important;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  backdrop-filter: blur(4px) !important;

  .top-right-modal-content {
    // background-color: #fff;
    position: absolute;
    right: 98px;
    top: 25px;
    @media (max-width: 800px) {
      right: 55px;
      top: 18px;
    }
    @media (max-width: 500px) {
      right: 35px;
    }
    // border-radius: 40px !important;
    // padding: 50px 45px;
  }
}

table {
  border-collapse: separate !important;
}
.r-table {
  // border: initial;
  th.sortable:hover {
    color: #2062f4 !important;
  }

  thead {
    th {
      box-shadow: initial;
      border: initial;
      text-align: left;
      font-weight: 700;

      &.sorted-asc {
        box-shadow: inset 0 3px 0 0 #2062f4;
      }

      &.sorted-desc {
        box-shadow: inset 0 -3px 0 0 #2062f4;
      }
    }
  }

  tr {
    td,
    th {
      padding-top: 20px;
      padding-bottom: 16px;
      border: initial;
    }

    th {
      // padding-left: 2em;
      padding-bottom: 0px;
      font-size: 12px;
      font-weight: 500;
      color: #04004d;
    }
  }

  tbody {
    // tr {
    //   td {
    //     // border-bottom: 1px solid #d7d7d7;
    //     // background: #fff;
    //   }
    // }

    tr:last-of-type {
      td {
        border-bottom: initial;
      }
    }
  }
}

.table-divided-row {
  border-collapse: separate;
  border-spacing: 0px 0.6rem;
  padding-bottom: 29px;
  width: 100% !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  th {
    padding-left: 2em !important;
    padding-bottom: 9px !important;
    border-bottom: 1px solid #d7d7d7 !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    color: #04004d !important;
  }

  td {
    padding-top: 20px;
    // padding-bottom: 20px;
    padding-left: 2em !important;
    border-bottom: 1px solid #d7d7d7;
    outline: initial !important;
    background: #fff;
  }
  .table-row {
    background: #fff;
    border-radius: 20px;
    width: 100%;
    border-bottom: 1px solid red;

    &.table-row-shadow {
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.05);
      margin-bottom: 30px;
    }
  }

  tr:last-of-type td {
    border-bottom: initial;
  }

  p,
  h6 {
    margin-bottom: initial;
  }

  tbody {
    > tr[role="row"] > td:first-child:before,
    > tr[role="row"] > th:first-child:before {
      top: unset;
      box-shadow: initial;
      background-color: #2062f4;
      font-size: 12px;
    }

    tr {
      &:last-child {
        td {
          border: none !important;
        }
      }
      td {
        padding: 0.75rem 32px;
        border-bottom: 1px solid #d7d7d7 !important;

        li {
          padding: 0 !important;
        }
      }
    }
  }

  td,
  th {
    padding: 1.5rem;
    border: initial;
  }

  th.empty {
    &:before,
    &:after {
      content: "";
    }
  }
}

.table-divided {
  border-collapse: separate;
  border-spacing: 0px 0.6rem;
  padding-bottom: 29px;
  width: 100% !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  th {
    padding-left: 2em !important;
  }

  td {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 2em !important;

    // border-bottom: 1px solid #d7d7d7;

    outline: initial !important;
    background: #fff;
  }
  .table-row {
    background: #fff;
    padding: 0 32px !important;
    margin-bottom: 25px;
    border-radius: 20px;
    width: 100%;
    &.table-row-shadow {
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.05);
      margin-bottom: 30px;
    }
  }
  tr:last-of-type td {
    border-bottom: initial;
  }

  p,
  h6 {
    margin-bottom: initial;
  }

  tbody {
    > tr[role="row"] > td:first-child:before,
    > tr[role="row"] > th:first-child:before {
      top: unset;
      box-shadow: initial;
      background-color: #2062f4;
      font-size: 12px;
    }

    tr {
      border-radius: 20px !important;
      td {
        padding: 0.75rem 32px;
        &:first-child {
          // border-top: 20px;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }
        &:last-child {
          // border-top: 20px;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }

        li {
          padding: 0 !important;
        }
      }
    }
  }

  td,
  th {
    padding: 1.5rem;
    border: initial;
  }

  th.empty {
    &:before,
    &:after {
      content: "";
    }
  }
}

.rounded {
  .table-divided {
    tr {
      border-radius: 20px;
    }
    td {
      border-radius: initial;
      &:first-child {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }

      &:last-child {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }
  }
}

.rtl {
  .rounded {
    .table-divided {
      &table {
        td {
          border-radius: initial;
          &:first-child {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
          }

          &:last-child {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
          }
        }
      }
    }
  }
}

.field-error {
  font-size: 13px;
}

// .form-check-input{
// }
.form-switch .form-check-input {
  background-color: rgba(60, 60, 67, 0.3) !important;
  background-image: url("../img/knob.svg") !important;
}
.form-switch .form-check-input:focus {
  box-shadow: none !important;
}
.form-switch .form-check-input:checked {
  background-image: url("../img/knob.svg") !important;
}

.responsive-table {
  @media only screen and (max-width: 800px) {
    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid #ccc;
    }

    td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50%;
      white-space: normal;
      text-align: left;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      text-align: left;
      font-weight: bold;
    }

    /*
      Label the data
      */
    td:before {
      content: attr(data-title);
    }
  }
}
.pagination-md .page-link {
  padding: 6px 12px !important;
  font-size: 14px;
  background: transparent;
  color: black;
  border: none;
  margin: 3px 4px 0;
}

.page-item.disabled .page-link {
  background: unset !important;
}
.page-item.active .page-link {
  background: #2062f4;
  border-radius: 3px;
  &:focus {
    outline: none !important;
    border: 1px solid rgba(32, 98, 244);
    box-shadow: unset;
  }
}

.page-link:focus {
  // z-index: 3;
  // color: #0a58ca;
  // background-color: #e9ecef;
  // outline: 0;
  box-shadow: unset;
}

.page-link:hover {
  // z-index: 2;
  // color: #0a58ca;
  // background-color: #e9ecef;
  box-shadow: unset;

  border: unset;
}
.modal-sm {
  max-width: 380px !important;
}
.modal-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.35px;
  color: #032041;
  border-bottom: 1px solid #e5ecf2;
}
.modal-button {
  background: linear-gradient(93.88deg, #49a8f8 6.88%, #0053f4 74.45%);
  border-radius: 5px;
  color: #fff;
  border: none;
  padding: 12px;
}
.delete-modal {
  margin-top: 4rem;
}
.delete-modal-subtext {
  font-weight: 400;
  font-size: 14px;
}
.delete-btn,
.primary-btn {
  background: linear-gradient(93.88deg, #49a8f8 6.88%, #0053f4 74.45%);
  border-radius: 16px;
  color: #fff;
  border: none;
  font-size: 14px;
  font-weight: 700;
  padding: 12px;
  width: 100%;
}
.cancel-btn {
  background-color: #ccc;
  color: #fff;
  margin-left: 12px;
}
.cancel-btn:focus {
  box-shadow: 0 0 0 0.25rem rgba(175, 177, 179, 0.25) !important;
}
.dropdown {
  background-color: #fff;
  .btn-secondary {
    border-color: "blue" !important;
  }
  .dropdown-container {
    font-size: 14px !important;
    border-radius: 14px !important;
    inset: 60% 0 auto auto !important;

    .dropdown-items {
      padding: 0.5rem 1rem !important;
    }
  }
}

.expense-details-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 650px;
  .card {
    width: 90%;
    max-width: 900px;
    border-radius: 15px !important;
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.1);
    border: none;
    .title {
      font-size: 24px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.36px;
      text-align: center;
      margin-top: 1rem;
    }
    .cardbody {
      margin: 3rem 0;
      padding: 1.5rem;
      .item {
        text-align: center;
        padding: 3rem;
        .title {
          font-size: 16px;
          font-weight: 400;
        }
        .content {
          font-size: 14px;
          color: #9da8b6;
          text-decoration: none;
        }
      }
    }
  }

  @media only screen and (max-width: 450px) {
    height: 100%;
    .card {
      height: 50%;
      .cardbody {
        margin: 0;
        .item {
          padding: 1rem 3rem;
        }
      }
    }
  }
}

.outline-btn {
  text-decoration: none;
  background-color: #fff;
  border: 2px solid #2062f4;
  color: #2062f4;
  padding: 0.7rem;
  border-radius: 14px;
  font-size: 13px;
  cursor: pointer;
  &:hover {
    background-color: #2062f4;
    color: #fff;
  }
}

.accordion-button {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 28px !important;
  padding-left: 0 !important;
  color: #17151e !important;
  &:focus {
    box-shadow: none !important;
  }
  &:not(.collapsed) {
    padding-left: 0.8px !important;
  }
  &::after {
    background-image: url("../img/plus.svg") !important;
    background-size: unset !important;
  }
  &:not(.collapsed)::after {
    background-image: url("../img/minus.svg") !important;
    height: 5px !important;
    -webkit-transform: unset !important;
    transform: unset !important;
  }
}

.accordion-body {
  padding-left: 0rem !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 16px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 28px;
  width: 26px;
  left: 0px;
  bottom: -5px;
  box-shadow: 0px 0.1px 0.3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.socials-connection-content {
  border: 1px solid #ccc;
  height: fit-content;
  width: 38%;
  text-align: center;
  border-radius: 16px;
  font-size: 16px;
  .logo-container {
    width: 100%;
    border-bottom: 1px solid #ccc;
  }
  @media only screen and (max-width: 768px) {
    height: 45%;
    width: 90%;
  }
}

.social-account-card {
  width: 29%;
  margin: 0 auto;
  border-radius: 15px;
  box-shadow: -20px 4px 30px 0px rgba(0, 0, 0, 0.03);
  font-size: 16px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  gap: 1.5rem;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
}

.instagram-modal {
  &.header {
    font-size: 28px;
    font-weight: 600;
    color: #032041;
  }
  &.subheader {
    color: #17151e;
    font-weight: 400;
    font-size: 13px;
  }
  &.account-card {
    font-size: 14px;
    text-align: center;
    color: #2062fa;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.3s;
    &.selected {
      background: #2062fa;
      color: #ffffff;
      opacity: 1;
    }
  }
  &.button {
    font-size: 14px;
    background: #2062fa;
    color: #ffffff;
  }
  &.gradient-button {
    font-size: 14px;
    background: linear-gradient(93.88deg, #49a8f8 6.88%, #0053f4 74.45%);
    color: #ffffff;
    border-radius: 16px;
  }
  & ol {
    padding-inline-start: 0px !important;
    font-size: 15px;
    color: #17151e;
    font-weight: 400;
  }
}
.learn-more {
  color: #2062f4;
  text-decoration: none;
}
:where(.css-dev-only-do-not-override-ph9edi).ant-drawer
  .ant-drawer-header-title {
  justify-content: flex-end !important;
}
:where(.css-dev-only-do-not-override-ph9edi).ant-drawer .ant-drawer-header {
  border-bottom: 0 !important;
}

.tab-container {
  display: flex;
  // flex: 0.7;
  width: 100%;
  font-size: 14px;
  @media only screen and (max-width: 768px) {
    overflow-x: scroll;
  }
}
.tab {
  width: fit-content;
  margin-right: 4rem;
  white-space: nowrap;
  font-weight: 400;
  cursor: pointer;
  padding: 0.8rem 0;
}
.active-tab {
  color: #49a8f8 !important;
  border-bottom: 3px solid #49a8f8;
  // padding-left: 10px;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-drawer .ant-drawer-close {
  background: #c0ccda !important;
  color: #fff !important;
  font-weight: 800 !important;
  padding: 0.5rem 0.6rem !important;
  border-radius: 50% !important;
  font-size: 13px !important;
}

.error {
  img {
    height: 70vh;
  }
}

.border-radius-16 {
  border-radius: 16px !important;
}
.font-12px {
  font-size: 12px;
}
.font-14px {
  font-size: 14px;
}
.font-16px {
  font-size: 16px;
}
.font-weight-600 {
  font-weight: 600;
}

//Dashboard Invoice cards
.dashboard-invoice-card {
  width: 145px;
  padding: 0.6rem;
  margin-right: 0.5rem;
  border-radius: 24px;
  max-height: 160px;
  @media screen and (max-width: 475px) {
    padding: 0.4rem;
    margin-right: 0.2rem;
    max-height: 180px;
  }

  .card-content {
    background-color: #fff;
    padding: 0.5rem 0.35rem;
    border-radius: 12px;
    font-size: 11px;
    color: #04004d;
  }
}

.card1-bg {
  background-color: #e8f7ff;
}
.card2-bg {
  background-color: #ede9ff;
}

.ant-select-selector {
  height: 65px !important;
  border-radius: 16px !important;
  font-size: 16px !important;
  font-weight: 400;
  border: 1px solid #49a8f8 !important;
  padding: 9px 18px !important;
  .ant-select-selection-search {
    .ant-select-selection-search-input {
      height: 48px !important;
      font-size: 16px !important;
    }
  }
}

.modal-padding {
  padding: 32px 49px;
  @media screen and (max-width: 450px) {
    padding: 32px 20px;
  }
}
.padding-0 {
  padding: 0px;
}
.css-1s2u09g-control {
  border-style: none !important;
}

.payment-page-container {
  width: 55% !important;
  #invoice {
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.1);
  }

  @media only screen and (max-width: 450px) {
    width: 100% !important;
  }
}

#invoice {
  background-color: #fff;
  padding: 40px;
  margin: 24px 0;

  th,
  td {
    text-align: left;
    border-bottom: 2px solid #f2f2f2;
    h6 {
      margin-bottom: 0;
    }
  }
  th {
    padding: 14px 8px;
  }
  td {
    padding: 6px 8px;
  }
  @media screen and (max-width: 450px) {
    padding: 16px;
  }
}

.invoice-cta {
  display: flex;
  flex-direction: row;
  padding: 1.5rem 3rem;

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column;
    padding: 1rem;
  }
}
.invoice-modal {
  padding: 1.5rem 3rem !important;
  @media screen and (max-width: 450px) {
    padding: 1rem !important;
  }
}

@media screen and (max-width: 450px) {
  .margin-top {
    margin-top: 1.2rem;
  }
  .margin-bottom {
    margin-bottom: 1rem;
  }
}

:where(.css-dev-only-do-not-override-ph9edi).ant-modal .ant-modal-content {
  border-radius: 30px !important;
  padding: 2rem !important;
}
