.dashboard {
  font-size: 14px !important;
  .css-tlfecz-indicatorContainer {
    color: #7337fd !important;
  }
  .css-1okebmr-indicatorSeparator {
    background-color: transparent !important;
  }

  .card {
    border-radius: 24px !important;
    border: none;
  }
  .card-sm-2 {
    padding: 0 0;
    @media (max-width: 750px) {
      padding: 0 12px;
      margin: 14px 0;
    }
  }
  .dashboard-analytics {
    .analytics-card {
      background: white;
      padding: 36px 1rem !important;
      margin-bottom: 25px;
      .card-sm {
        height: 202px;
        display: flex;
        padding: 20px 18px;
        justify-content: flex-start;
        @media (max-width: 525px) {
          margin-bottom: 12px;
        }

        h4 {
          font-size: 16px;
          font-weight: 600;
          white-space: nowrap;
          span {
            @media (max-width: 1000px) {
              display: none;
            }
          }
          @media (max-width: 1000px) {
            text-transform: capitalize;
          }
        }
        h5 {
          font-size: 22px;
          font-weight: 300;
        }
      }

      .revenue {
        background: linear-gradient(93.88deg, #49a8f8 6.88%, #0053f4 74.45%);
        border: 1px solid #d3dce6;
      }
      .posts,
      .clients {
        border: 1px solid #d3dce6;
      }
    }
  }
  .overview-card {
    // height: 562px;
    padding: 26px 20px 36px 20px;

    @media screen and (max-width: 450px) {
      padding: 26px 5px;
    }
    .finacial-overview {
      margin-bottom: 20px;
      .financial-overview-header {
        font-size: 16px;
        font-size: 700;
        opacity: 0.8;
        color: rgba(17, 19, 23, 1);
      }
      .financial-overview-chart {
        background: #f3f4f6;
        margin: 21px 0px;
        // padding: 12px 9px 15px 10px;
        border-radius: 24px;
        height: 350px;
        width: 100%;
        background: linear-gradient(to bottom, #3acfd5 0%, #3a4ed5 100%);
        padding: 1.5px;
        box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.05);
        .chart-container {
          background: #fff;
          padding: 5px 0px 5px 5px;
          height: 100%;
          width: 100%;
          margin: 0 auto;
          border-radius: 24px;
          border: 1px solid transparent;
          canvas {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
    }
    .invoice-overview {
      margin-bottom: 12px;
      h4 {
        font-size: 16px;
        color: rgb(21, 23, 27);
        font-size: 700;
        opacity: 0.8;
      }
      p {
        color: rgba(32, 98, 244, 1);
        font-size: 16px;
        font-weight: 300;
      }
    }
    .invoice-inactive-state {
      width: 50%;
      @media (max-width: 505px) {
        width: 65%;
      }
      @media (max-width: 400px) {
        width: 75%;
      }
      h5 {
        font-size: 19px;
        color: rgb(21, 23, 27);
        font-size: 700;
      }
      p {
        font-size: 16px;
        color: #17151e;
        opacity: 0.8;
        font-weight: 300;
      }
    }
  }
  .client-card {
    padding: 26px 21px;
    height: 100%;
    min-height: 567px;
    .client-overview-header {
      font-size: 16px;
      font-size: 700;
      opacity: 0.8;
      color: rgba(17, 19, 23, 1);
    }
    p {
      font-size: 16px;
      color: #17151e;
      opacity: 0.8;
      font-weight: 300;
    }

    .client-inactive-state {
      margin-top: 0 !important;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      h5 {
        font-size: 20px;
        font-weight: 900;
      }
      p {
        font-size: 14px;
        font-weight: 400px;
      }
    }
  }

  .create-button {
    background: #2062f4 !important;
    border-radius: 10px;
    display: flex;
    text-align: center;
    color: white;
    border: none;
    border-radius: 10px;
    @media (max-width: 400px) {
      width: 100% !important;
    }
    @media (max-width: 500px) {
      width: 75%;
    }
    h4 {
      font-weight: 500;
    }
  }
  .create-icon {
    background: #fff;
    width: 20px;
    height: 20px;
    margin-right: 18px;
    border-radius: 50%;
  }

  .calendar-card {
    padding: 32px 18px;
    // height:100%;
    .customize-calendar {
      font-family: unset;
      width: 100%;
      border: none;
    }
    hr {
      border: 1px solid #eff2f7;
      //   margin: 22px 0;
    }
    .upcoming-events-header {
      margin: 12px 0 26px 0;
      font-size: 18px;
      font-size: 700;
      opacity: 0.8;
      color: rgba(17, 19, 23, 1);
    }
    .upcoming-event {
      background: #f9fafc;
      border-radius: 24px;
      padding: 20px 19px;
      margin: 16px 10px;
      @media (max-width: 500px) {
        // display: block !important;
      }
      h4 {
        @media (max-width: 600px) {
          font-size: 18px;
          margin-bottom: 0;
        }
      }
      p {
        @media (max-width: 600px) {
          font-size: 13px;
        }
      }
      .event-arrow-icon {
        @media (max-width: 500px) {
          text-align: right;
        }
      }
      .event-details {
        h4 {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 12px;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 0;
        }
      }
      .event-icon {
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
        border-radius: 14px;
        padding: 14px 15px;
        @media (max-width: 600px) {
          // padding: 0.6em;
          padding: 12px;
        }
        @media (max-width: 500px) {
          margin: auto;
          width: 50px;
          margin-bottom: 14px;
          text-align: center;
          // padding: 0.3em;
        }
      }
    }
  }
  .card-header-text {
    font-size: 18px;
    font-size: 700;
    opacity: 0.8;
    color: rgba(17, 19, 23, 1);
  }
}

.post {
  hr {
    margin-bottom: 0;
    margin-top: 0;
  }
  .post-button-wrapper {
    margin: auto;
    width: 70%;
  }
  .post-button {
    margin: auto;
    width: 100%;
    margin: auto;
    background: #2062f4;
    border-radius: 16px;
  }
  .post-details {
    margin: 15px 0 15px;
  }
  .post-title {
    // color: red;
    margin-top: 8px;
    font-size: 20px;
    font-weight: 500;
  }
  .post-text-header {
    font-size: 18px;
    font-weight: 500;
  }
  .post-description {
    font-size: 10px;
    font-weight: 400px;
  }
  .post-img-wrapper {
    width: 134px;
    height: 129px;
    .post-img {
      width: 100%;
    }
  }
  .client-section {
    // border-right: 1px solid rgba(197, 197, 199, 0.4);
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0px;
      height: 60%;
      width: 1.5px;
      color: rgba(197, 197, 199, 0.4);
    }
    margin-right: 30px;
    .client-details {
      padding: 2px;
    }
    .client-section-header-text {
      font-size: 14px;
      font-weight: 600;
      color: #111317;
      opacity: 0.8;
    }
    .client-logo {
      background: linear-gradient(180deg, #6ad5ed 0%, #49a8f8 100%);
      font-size: 7px;
      padding: 3px;
      font-weight: 300;
      color: #fff;
      border-radius: 50%;
      height: 17px;
      width: 17px;
      margin-right: 6px;
    }
    .client-name {
      font-size: 10px;
      font-weight: 400;
    }
  }
  .channel-section {
    .channel-section-header-text {
      font-size: 14px;
      font-weight: 600;
      color: #111317;
      opacity: 0.8;
    }
    .tag {
      font-size: 10px;
      margin-right: 4px;
      padding: 4px 6px;
    }
    .tag-1 {
      background: #c2ffd0;
      border-radius: 20px;
    }
    .tag-2 {
      background: #e8f7ff;
      border-radius: 20px;
    }
  }
}
// .upcoming-event-modal {
//   // position: absolute;
//   // top: 50px;
//   // left: 50px;
//   // transform: translate(-50%, -50%);
//   //
// }
.event-img-wrapper {
  margin: auto;
  // width: 425px;
  // height: 285px;
  margin-top: 50px;
  margin-bottom: 38px;
  border-radius: 16px;
  // overflow: hidden;
  .event-img {
    width: 100%;
    border-radius: 16px;
    object-position: center;
    object-fit: fill;
  }
}
.event-description {
  font-size: 10px;
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 30px;
}
.event-header-title {
  font-size: 35px;
  font-weight: 500;
}
.event-button-wrapper {
  margin: auto;
  width: 70%;
  margin-bottom: 50px;
  .event-button {
    font-weight: 600;
    margin: auto;
    width: 100%;
    margin: auto;
    background: #2062f4;
    border-radius: 16px;
  }
}

.client-dashboard {
  .client-dashboard-left-section {
    .card {
      border: none;
      padding: 43px 40px;
      border-radius: 10px;
      .client-brandname {
        font-weight: 600;
        font-size: 20px;
        margin-top: 17px;
        margin-bottom: 25px;
      }
      h6 {
        font-size: 12px;
        color: #04004d;
      }
      p {
        font-size: 12px;
      }
    }
    .client-info-container {
      border-bottom: 1px solid #f2f2f2;
    }
    .tag-container {
      background: #e8f7ff;
      border-radius: 10px;
      padding: 8px 17.25px;
    }
    .tag-container-text {
      color: #2062f4;
      font-size: 12px;
      margin-left: 9px;
    }
  }
  .client-dashboard-right-section {
    .dashboard-nav {
      padding: 57px 79px;
      @media (max-width: 450px) {
        padding: 30px 15px;
      }
      border: none;
      border-radius: 10px;
      a {
        text-decoration: none;
        color: #04004d;
      }
      .card {
        padding: 56px 35px;
        border-radius: 20px;
        @media (max-width: 450px) {
          padding: 30px 15px;
        }
      }
      h1 {
        font-weight: 600;
        font-size: 19px;
        margin-top: 32px;
        margin-bottom: 16px;
      }
      p {
        font-size: 12px;
      }
    }
  }
}

.scheduled-post-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e9;
  .post-media {
    width: 20%;
    img {
      height: 100px;
      width: 100px;
      border-radius: 20px;
    }
  }
  .post-content {
    width: 78%;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
  }
  .client-details-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0.5rem;
    span {
      font-size: 10px;
      color: #111317;
      font-weight: 600;
      margin-right: 1rem;
    }
    img {
      width: 14px;
      height: 14px;
    }
  }
}

.finance-tab {
  .left-actions {
    gap: 10px;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column !important;
    align-items: flex-start !important;

    .left-actions {
      width: 100% !important;
      .create-button,
      .btn-lg {
        width: 50% !important;
      }
    }
    .white-button {
      margin-top: 1rem;
    }
  }
}
