.business {
  &__right-section {
    border-right: 1px solid #e0e6ed;
    padding-top: 5rem;
    &__image-upload-placeholder {
      border: 3px dashed #e5e9f2;
      border-radius: 24px;
      cursor: pointer;
      &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.35px;
        color: #111317;
      }
      &__image-upload-blue {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 1.25px;
        color: #2062f4;
      }
    }
  }
  &__address-title {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.35px;
    color: #17151e;
  }
  &__accounts-title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.35px;
    color: #17151e;
  }
  &__left-section {
    &__input {
      border: 1px solid #49a8f8;
      box-sizing: border-box;
      border-radius: 16px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
      color: #6e7191;
    }
    &__social-container {
      background: #f9fafc;
      border-radius: 10px;
      cursor: pointer;
      &__text {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        letter-spacing: 0.25px;
        color: #6e7191;
        margin: 0 0.6rem;
      }
    }
    &__done-btn {
      background: linear-gradient(93.88deg, #49a8f8 6.88%, #0053f4 74.45%);
      border-radius: 16px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 1.25px;
      border-style: none;
      color: #fff;

      &:disabled {
        background: #e5ecf2;
        color: #2062f4;
      }
    }
  }
}

.text-left {
  text-align: left;
}
