@tailwind base;
@tailwind components;
@tailwind utilities;


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* .react-time-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  background: #FFFFFF !important;
  border: 1px solid #49A8F8 !important;
  border-radius: 16px !important;
  padding: 10px 20px !important;
  height: 64px !important;
  width: 350px !important;
} */

.react-datetime-picker__wrapper {
  border: none !important;
}

@media only screen and (max-width: 768px) {
  .react-time-picker__wrapper {
    width: 280px !important;
  }
}