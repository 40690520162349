.client-management {
  position: relative;
  height: calc(110vh - 112px);

  .client-management-control {
    @media (max-width: 745px) {
      margin: auto;
    }
    @media (min-width: 1000px) and (max-width: 1030px) {
      margin: auto;
    }
  }
  .client-analytics-card {
    border-radius: 20px !important;
    border: none;
    padding-left: 50px;
    padding-right: 50px;
    width: 292.49px;

    @media (max-width: 855px) {
      width: 45%;
      padding-top: 0.5em !important;
      padding-bottom: 0.5em !important ;
    }
    @media (max-width: 745px) {
      width: 100%;
      margin-bottom: 12px;
    }
    @media (max-width: 760px) {
      padding-top: 0.7em !important;
      padding-bottom: 0.7em !important ;
    }
    @media screen and (max-width: 440px) {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
    @media (min-width: 1000px) and (max-width: 1030px) {
      width: 100%;
      margin-bottom: 14px;
    }
    // .client-analytics {
    //   @media (max-width: 760px) {
    //     width: 60%;
    //   }
    // }
    .client-analytics-text {
      font-size: 20px;
      font-weight: 600;
      color: #04004d;
      @media (max-width: 760px) {
        font-size: 18px;
      }
    }
    .client-count {
      font-size: 23px;
      font-weight: 600;
      color: #04004d;
      @media (max-width: 855px) {
        font-size: 23px;
      }
    }
  }
  .client-icon-card {
    padding: 12px;
    border-radius: 10px;
    border: none;
    &.download {
      padding: 8px;
      margin: 0 15px;
    }
    &.list {
      padding: 10px;
    }
  }
  .client-inactive-state {
    margin-top: 24.8px;
    margin-bottom: 37px;

    // width: 100%;
    // position: absolute;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    .client-inactive-state-card.empty-state {
      margin: auto;
      width: 100%;
      // padding: 50px 0;
      height: calc(100vh - 240px);
      border-radius: 22px;
      border: none;
      display: flex;
      justify-content: center;
      // align-items: center;
      @media (max-width: 425px) {
        width: 100%;
      }
      .client-inactive-header-text {
        font-size: 18px;
        font-weight: 600;
        @media (max-width: 450px) {
          font-size: 16px;
        }
      }
      .client-inactive-subhead-text {
        width: 100%;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.374px;
        // @media (max-width: 600px) {
        //   width: 70%;
        // }
        // @media (max-width: 450px) {
        //   font-size: 80%;
        // }
      }
      .client-inactive-subhead-text-wrapper {
        margin: 42px 0 28px 0;
      }
      .client-create-button {
        background: linear-gradient(
          93.88deg,
          #49a8f8 6.88%,
          #0053f4 74.45%
        ) !important;
        color: white;
        border-radius: 16px;
        margin: auto;
        width: 38%;
        padding: 0.855em;
        @media (max-width: 710px) {
          width: 85%;
        }
        @media (max-width: 600px) {
          width: 70%;
        }
        @media (max-width: 500px) {
          width: 100%;
        }
      }
    }
  }
  //   .client-ctrl-icon{
  //       background: ff;
  //   }
  .create-button {
    background: #2062f4 !important;
    margin: auto;
    border-radius: 10px;
    padding: 10px 15px;
    // width: 213px;
    text-align: center;
    color: white;
    border: none;

    h6 {
      font-weight: 500;
      font-size: 14px;
    }
    .create-icon {
      margin-right: 10px;
      background: #fff;
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  }
}
.delete-client-wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.add-client-wrapper,
.delete-client-wrapper {
  margin-top: 15px;
  padding-left: 45px;
  padding-right: 45px;
  @media screen and (max-width: 450px) {
    padding: 0rem;
  }
  .user-icon-container {
    background: #e8f7ff;
    margin: auto;
    padding-top: 17px;
    width: 82px;
    height: 82px;
    border-radius: 50%;
  }
  .add-client-text {
    font-size: 0.8rem;
    h3 {
      font-size: 30px;
      font-weight: 700;
    }
    p {
      font-size: 13px;
      font-weight: 400;
      opacity: 0.8;
    }
  }
  input,
  select {
    border-radius: 16px !important;
    padding: 14px 20px;
    // font-size: 0.8rem !important;
    margin-bottom: 20px;
    // font-weight: 300;
    font-size: 14px;
    color: #6e7191;

    // width:100%;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    @media (min-width: 1600px) {
      padding: 21px 24px !important;
    }
  }
  button {
    background: linear-gradient(93.88deg, #49a8f8 6.88%, #0053f4 74.45%);
    border-radius: 16px;
    color: #fff;
    border: none;
    padding: 12px;
  }
  .btn-outline-primary {
    background: transparent;
    color: #2062f4;
    border: 1px solid #2062f4;
  }
}

.grid-view {
  margin-top: 54.8px;
  .grid-view-card {
    padding: 18px 12px;
    border-radius: 20px;
    border: none;
    @media (min-width: 1600px) {
      padding: 25px 33px;
    }
    // width:268px;
  }

  .client-details {
    background: #f9fafc;
    border-radius: 10px;
    padding: 9px 12px;
    .client-logo-wrapper {
      margin-right: 26.54px;
    }
    .client-name {
      font-size: 14px;
      font-weight: 600;
    }
    .client-project {
      font-size: 12px;
      font-weight: 400;
    }
  }
  .project-details {
    margin-top: 20px;
    .project-timeline-header,
    .date-created-header {
      font-size: 12px;
      font-weight: 600;
      color: #04004d;
    }
    .project-timeline,
    .date-created {
      font-size: 12px;
      font-weight: 400;
    }
  }
  .tag-container {
    background: #e8f7ff;
    border-radius: 10px;
    padding: 8px 17.25px;
  }
  .tag-container-text {
    color: #2062f4;
    font-size: 12px;
    margin-left: 9px;
  }
}

.list-client-item-bold {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.list-client-item {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
}

.list-client-delete {
  color: #ed2e7e;
}
.list-client-tag {
  background: #e8f7ff !important;
  color: #2062f4;
  padding: 8px 12px;
  border-radius: 10px !important;
  img {
    margin-right: 3px;
    margin-bottom: 2px;
  }
}

.client-details {
  .client-profile-card {
    padding: 22px 70px 22px 40px;
    background: #e8f7ff !important;
    border: 1px solid #49a8f8;
    border-radius: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    font-size: 12px;
    font-weight: 400;

    @media screen and (max-width: 475px) {
      justify-content: space-between;
      align-items: center;
    }
  }
  .client-name {
    font-size: 14px;
    font-weight: 600;
    // margin-bottom: 10px;
  }
  .project-timeline,
  .client-name,
  .business-category,
  .client-portal {
    h1 {
      color: #04004d;
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 13px;
    }
    p {
      color: #04004d;
      font-size: 12px;
      font-weight: 400;
      // margin-bottom: 13px;
    }
  }
  .copy-link {
    color: #2062f4;
    cursor: pointer;
    font-weight: 500;
  }
  @media screen and (max-width: 475px) {
    .client-portal {
      display: none;
    }
  }
  .client-portal {
    p {
      color: #2062f4;
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 13px;
    }
  }
  .content-calendar {
    padding: 0 20px;
    background: #fff;
    border-radius: 24px !important;
    border: none !important;
    h2 {
      font-size: 20px;
      font-weight: 600;
      color: #04004d;
      margin-bottom: 26px;
    }
    .first-section {
      padding-top: 40px;
      padding-bottom: 40px;
      // border-right: 1px solid #e8f7ff;
    }
    .second-section {
      padding: 12px 0px 0 30px !important;
      .month {
        font-size: 12px;
        font-weight: 400;
        color: #d3dce6;

        margin-bottom: 16.5px;
        &.active {
          background: linear-gradient(164.82deg, #2062f4 3.9%, #7337fd 100%);
          border-radius: 4px;
          padding: 8px;
        }
      }
    }
  }
  .client-basic-information,
  .client-invoices,
  .client-connected-account-card {
    padding: 35px;
    background: #fff;
    border-radius: 24px !important;
    border: none !important;
    .schedule-post-button {
      background: #2062f4 !important;

      border-radius: 10px;
      padding: 10px 20px;
      // width: 213px;
      text-align: center;
      color: white;
      border: none;

      h6 {
        font-weight: 500;
        font-size: 14px;
      }
      .post-icon {
        margin-right: 10px;
        background: #fff;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }
    h2 {
      font-size: 20px;
      font-weight: 600;
      color: #04004d;
      margin-bottom: 26px;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 27px;
      letter-spacing: 0.374px;
      color: #04004d;
    }
    button {
      font-size: 14px;
    }
    .client-information-item {
      background: #f9fafc;
      border-radius: 10px;
      padding: 10px 15px 10px 15px;
      margin-bottom: 20px;
      h2 {
        color: #6e7191;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
      }
      // .information-icon {
      //   border: #fff 1px solid;
      //   // border-radius: 10px;
      // }
    }
  }
  .client-accounts {
    .account {
      background: #f9fafc;
      border-radius: 10px;
      padding: 10px 20px 10px 10px !important;
      margin-bottom: 25px;
      p {
        font-size: 15px;
        font-weight: 400;
        color: #6e7191;
        margin-bottom: 0;
        margin-right: 10px;
      }
      img {
        margin-right: 13px;
      }
      // margin-right: 26px;
    }
  }
}

.invoice-data-item {
  color: #9da8b6;
  font-size: 12px;
  font-weight: 300;
}
.invoice-status {
  background: rgba(87, 217, 163, 0.15);
  border-radius: 10px;
  padding: 10px 19px;
  color: #57d9a3;
  font-weight: 500;
  font-size: 12px;
}
.invoice-data-item-bold {
  font-size: 12px;
  font-weight: 600;
  color: #9da8b6;
}

.client-details-settings-modal {
  margin: auto;
  width: 75%;
  padding: 50px 0;
  h1 {
    font-size: 30px;
    font-weight: 700;
  }
  p {
    font-size: 13px;
    font-weight: 400;
  }
  input {
    font-weight: 500;
    font-size: 14px;
    color: #6e7191;
    border-radius: 16px;
    padding: 21px 24px;
    width: 100%;
    margin: 55px 0;
    border: 1px solid #e5ecf2;
    &:focus {
      border-color: 1px solid #2062f4;
    }
  }
  button {
    border-radius: 16px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
  }
  .contained {
    background-color: #2062f4 !important;
  }
  .outlined {
    background-color: #ffffff;
    color: #2062f4 !important;
    border: none;
    &:hover,
    &:active {
      color: #2062f4 !important;
    }
  }
}
.add-client-wrapper {
  .css-6j8wv5-Input {
    margin: 0;
  }
  .css-319lph-ValueContainer {
    padding: 0 8px !important;
  }
  .css-tlfecz-indicatorContainer,
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-6j8wv5-Input {
    @media (min-width: 767px) {
      // padding: 65px 87px 90px !important;
      padding: 5px !important;
    }
    @media (min-width: 4500px) {
      // padding: 65px 87px 90px !important;
      padding: 3px !important;
    }
  }
  .css-1s2u09g-control {
    border-radius: 16px;

    @media (min-width: 1200px) {
      // padding: 65px 87px 90px !important;
      padding: 7px !important;
    }
  }
}
.img-holder {
  width: 50px;
  height: 50px;
  background: #e0e6ed;
  border-radius: 10px;
}
.add-client-logo {
  width: 50px;
  height: 50px;
  // background: #e0e6ed;
  border-radius: 10px;
}
.client-details-img-wrapper {
  background: #ffffff;
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 0.2rem;
  // width: 70px;
  // height: 60px;
}

select {
  appearance: none;
  background-image: url("../img/select.png");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1.3em;
}
