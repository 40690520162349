.container {

}
.container > div[class='modal-dialog'] {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    max-width: unset;
}

.container  div[class='modal-content'] {
    padding: 10px;
    width: min(90%, 800px) !important;
}

.container  div[class='modal-header'] button {
    background-color: transparent;
    border: none;
}

.container h5 {
    border-bottom: none;
}

.title {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    text-transform: capitalize;
}

.subtitle {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #6E7191;
}

.inputs {
    margin-top: 24px;
}

.inputBox {
    margin-bottom: 20px;
}

.inputBox input {
    background: #FFFFFF;
    border: 1px solid #49A8F8;
    border-radius: 8px;
    height: 45px;
    min-width: 95%;
    padding: 0 10px;
}

.inputTitle {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #111317;
    margin-bottom: 6px;
}

.submit {
    display: flex;
    justify-content: flex-end;
    margin-top: 22px;
}

.submit button {
    background: #2062F4;
    border-radius: 10px;
    height: 40px;
    width: 170px;
    border: none;
    color: #FFFFFF;
}

@media (min-width: 992px) {

    .container  div[class='modal-content'] {
        padding: 30px 40px;
    }

    .inputBox input {
        border-radius: 16px;
        height: 67px;
        min-width: 400px;
    }

    .title {
        font-size: 24px;
        line-height: 36px;
    }

    .subtitle {
        font-size: 14px;
        line-height: 22px;
    }

    .inputTitle {
        font-size: 14px;
        line-height: 22px;
    }
}
