@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

.finetuneWrapper {
  margin-top: 1rem;
  .finetuneIcon_holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    .love_share {
      display: flex;
      gap: 1rem;
    }
  }

  img {
    width: 1.3rem;
  }
  .finetuneName {
    font-weight: 600;
    color: #262626;
  }
  .fbpreviewholder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .leftComment {
      display: flex;
      align-items: center;
    }
    .rightComment {
      display: flex;
      gap: 0.7rem;
      span {
        color: #606770;
        font-size: 10px;
      }
    }
  }
  .text_comment {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 13px;
      font-weight: 700;
      color: #616770;
    }
    @media (max-width: 400px) {
      gap: 0.3rem;
    }
    span {
      @media (max-width: 400px) {
        font-size: 0.55rem;
      }
    }
  }
  .comment {
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
    gap: 3rem;
    @media (max-width: 400px) {
      gap: 1rem;
    }
  }
  .lnicon_text_wrapper {
    display: flex;
    gap: 1rem;
    .ln_icons {
      display: flex;
      gap: 0.2rem;
    }
    .ln_icon_text {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      span {
        font-size: 0.7rem;
        color: #565c65;
      }
      .dot {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #565c65;
      }
    }
  }

  .lnComment {
    margin-top: 0.5rem;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    @media (max-width: 400px) {
      gap: 0.3rem;
    }
  }
}

.icon {
  width: 16px;
  height: 16px;
  border: 1.01081px solid #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.month_day {
  margin-top: -0.9rem;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
}

.finetuneTabContent {
  padding: 1.5rem;
  @media (max-width: 400px) {
    padding: 0px;
  }
}

.previewWrapper {
  background: #f9fafc;
  padding: 10px 0px;
  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #032041;
    opacity: 0.8;
    text-align: center;
    margin-bottom: 16px;
  }
  @media (max-width: 400px) {
    padding: 10px;
  }
}

.preview-iG-wrapper {
  width: 350px;
  margin: auto;
  background: #fff;
  padding: 16px;
  .preview-dp-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    .dp {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }
    span {
      font-size: 14px;
      font-size: 600;
    }
    p {
      font-size: 14px;
      font-size: 600;
    }
  }
  .previewText {
    font-weight: 300;
    font-size: 14px;
    line-height: 15px;
    color: #344563;
  }
  .img-holder {
    height: 350px;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @media (max-width: 400px) {
    width: 100%;
  }
}

.preview-fb-wrapper {
  width: 402px;
  margin: auto;
  background: #fff;
  padding: 16px;
  .preview-dp-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    .dp {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }
    span {
      font-size: 14px;
      font-size: 600;
    }
    p {
      font-size: 14px;
      font-size: 600;
    }
  }
  .previewText {
    font-weight: 300;
    font-size: 14px;
    line-height: 15px;
    color: #344563;
  }
  .img-holder {
    height: 402px;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @media (max-width: 400px) {
    width: 100%;
  }
}

.datePickerStyle {
  background-color: #ffffff;
  border: 1px solid #49a8f8;
  border-radius: 16px;
  padding: 10px 20px;
  width: 100%;

  @include placeholder {
    font-size: 14px;
    color: #6e7191;
  }
}

.caption {
  resize: none;
  border: 1px solid #49a8f8;
  border-radius: 16px;
  height: 200px;
  width: 100%;
  padding: 5px 20px;

  &:focus {
    outline: none;
  }
}

.characters {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  letter-spacing: 0.25px;
  color: #6e7191;
}

.toggleholder {
  display: flex;
  align-items: center;
  gap: 11px;

  .react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  .react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 40px;
    height: 20px;
    background: rgba(73, 168, 248, 0.7);
    border-radius: 100px;
    position: relative;
    transition: background-color 0.2s;
  }

  .react-switch-label {
    & .react-switch-button {
      content: "";
      position: absolute;
      top: 0px;
      left: -2px;
      //   right: -7px;
      width: 20px;
      height: 20px;
      border-radius: 45px;
      transition: 0.2s;
      background: #49a8f8;
      box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    }
  }

  .react-switch-checkbox:checked {
    + .react-switch-label {
      & .react-switch-button {
        left: calc(100% - 0px);
        transform: translateX(-100%);
      }
    }
  }

  .react-switch-label:active {
    & .react-switch-button {
      width: 60px;
    }
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-top: 13px;
    letter-spacing: 0.25px;
    color: #6e7191;
  }
}

.schedulePost-modalWrapper {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.374px;
  color: #000000;
  .modalHeader {
    margin: 45px 0px;
    text-align: center;
  }

  h5 {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.374px;
    color: #000000;
  }
}
