.noscheduleholder {
    padding: 80px 0px;

    .content {
        width: 500px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;

        @media(max-width: 576px) {
            width: 100%;
            margin: 0px;
        }

        h4 {
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
        }

        button {
            background: linear-gradient(93.88deg, #49A8F8 6.88%, #0053F4 74.45%);
            border-radius: 16px;
            border: none;
            width: 325px;
            padding: 11px 0px;

            @media(max-width: 576px) {
                width: 90%;
            }

            a {
                text-decoration: none;
                color: #F7F7FC;
                font-weight: 700;
                font-size: 14px;
                line-height: 21px;
            }
        }
    }
}