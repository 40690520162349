.subscription {
  padding: 0 2rem;
  &__header {
    &__text {
      font-style: normal;
      line-height: 28px;
      letter-spacing: 0.35px;
      color: #111317;
      margin-left: 10px;
      h1 {
        font-size: 28px;
        font-weight: 600;
      }
      p {
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }
  &__plans-container {
    padding: 0 1.5rem;
    @media (max-width: 745px) {
      padding: 0;
    }
  }
  &__card {
    &__content {
      background: #ffffff;
      border-radius: 20px;
      border: solid 1px #d3dce6;
      max-width: 320px;
      min-height: 493px;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      margin-bottom: 2.5rem;

      @media only screen and (max-width: 540px) {
        max-width: unset;
      }
      &__plan {
        border-radius: 20px;
      }
      div:last-of-type {
        margin-top: auto !important;
      }
      &__active {
        border: solid 1px #7337fd;
        box-shadow: 0 4px 44px rgba(0, 0, 0, 0.16);
      }
      &__description {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 0.35px;
        color: #17151e;
      }
      &__plan-type {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0.35px;
        color: #032041;
      }
      &__btn {
        border-radius: 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        align-items: center;
        text-align: center;
        letter-spacing: 1.25px;
        align-self: flex-end;
        border-radius: 10px !important;
        background: linear-gradient(164.82deg, #f9fafc 3.9%, #f9fafc 100%);
        color: #032041 !important;
        border: 1px solid #d3dce6 !important;
        &__active {
          color: #ffffff !important;
          background: linear-gradient(164.82deg, #2062f4 3.9%, #7337fd 100%);
          transition: all 0.5s;
        }
      }

      &__regular-text {
        font-weight: normal;
        font-size: 14px;
        line-height: 28px;
        display: flex;
        align-items: center;
        letter-spacing: 0.35px;
        color: #17151e;
      }
      &__price-text {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        letter-spacing: 0.36px;
        color: #032041;
        margin-top: -14px;
        &__subscript {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 28px;
          display: flex;
          align-items: center;
          letter-spacing: 0.35px;
          color: #00d67d;
        }
        &__duration {
          font-weight: 600;
          font-size: 14px;
          line-height: 28px;
          letter-spacing: 0.35px;
          color: #032041;
          &-active {
            color: #00d67d;
          }
        }
      }
      &__payment-button {
        background: linear-gradient(93.88deg, #49a8f8 6.88%, #0053f4 74.45%);
        border-radius: 16px;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 1.25px;
        color: #f7f7fc;
        border-style: none;
        width: 100%;
        cursor: pointer;
      }
      &__modal {
        &__title {
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 39px;
          text-align: center;
          display: flex;
          align-items: center;
          letter-spacing: -0.0733333px;
          color: #000000;
        }
        &__subtext {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 24px;
          text-align: center;
          color: #17151e;
        }
        &__payment {
          &__option-container {
            background: #ffffff;
            box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
            border-radius: 16px;
            width: 80%;
            display: flex;
            justify-content: center;
            cursor: pointer;
          }
          &__name {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            display: flex;
            align-items: center;
            letter-spacing: 0.25px;
            color: #6e7191;
          }
        }
      }
    }
  }
}
.cancel-subcription-modal {
  h3 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 54px;
  }
  p {
    font-size: 0.78rem;
    font-weight: 400;
  }
  .button {
    letter-spacing: 1.25px !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    padding: 10px;
    border: none;
    border-radius: 10px;
    &__action-button1 {
      color: #f7f7fc !important;
      background-color: #2062f4 !important;
    }
    &__action-button2 {
      color: #2062f4 !important;
      background-color: #fff !important;
    }
  }
}

//Save Credit Cards card for payment
.faq-section {
  width: 85% !important;
  .main-header {
    text-transform: uppercase;
    color: #0b6bff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
  }
  .subheader {
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 28px;
    color: #17151e;
    line-height: 28px;
    letter-spacing: 0.35px;
    margin-bottom: 2.5rem;
  }
  @media (max-width: 425px) {
    width: 100% !important;
  }
}
.payment-intiation-card {
  width: 90% !important;
  background-color: #f8f8f8 !important;
  border-radius: 20px;
  border: 1px solid #c0ccda;
  .header {
    border-bottom: 1px solid #e5ecf2;
    h1 {
      font-size: 1.1rem;
      line-height: 28px;
      align-items: center;
      letter-spacing: 0.35px;
      font-weight: 700;
      text-transform: uppercase;
      color: #032041;
    }
  }

  button {
    background: linear-gradient(93.88deg, #49a8f8 6.88%, #0053f4 74.45%);
    border-radius: 16px;
    color: #fff;
    border: none;
    padding: 12px;
    width: 100%;
  }
  @media (max-width: 425px) {
    width: 100% !important;
    margin-top: 2rem;
  }
}

.cards-container {
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media only screen and (max-width: 450px) {
    flex-direction: column;
  }
}

.finances {
  &__top-cards {
    padding: 0 1rem;
    background: #ffffff;
    border-radius: 24px;
    @media screen and (max-width: 450px) {
      text-align: center;
      .pending-overdue {
        text-align: center;
      }
    }

    &__left-half {
      width: 70%;
      padding-right: 1rem;
      border-right: 1px solid #f2f2f2;
    }
    &__title {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.5px;
      color: #000000;
      display: flex;
      flex-direction: row;
    }
    &__currency {
      font-style: normal;
      font-weight: 600;
      font-size: 34px;
      line-height: 41px;
      letter-spacing: 0.374px;
      color: #2062f4;
      @media screen and (max-width: 450px) {
        font-size: 24px;
      }
    }
    &__amount-big {
      font-weight: 600;
      font-size: 34px;
      line-height: 41px;
      letter-spacing: 0.374px;
      color: #000000;
      white-space: nowrap;
      @media screen and (max-width: 450px) {
        font-size: 24px;
        text-align: left;
      }
    }
    &__amount-small {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
      color: #04004d;
      white-space: nowrap;

      // @media screen and (max-width: 450px) {
      //   justify-content: center;
      // }
    }
    &__bonus {
      background: #d2ffca;
      border-radius: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.25px;
      color: #5fc54c;
    }
  }
  &__top-cards:nth-of-type(1) {
    padding: 1rem 3rem;
    width: 40%;
  }
  &__top-cards:nth-of-type(2),
  &__top-cards:nth-of-type(3) {
    width: 30%;
    padding: 1.2rem 3rem;
  }

  @media only screen and (max-width: 450px) {
    &__top-cards:nth-of-type(1) {
      width: 100%;
      padding: 1rem;
    }
    &__top-cards:nth-of-type(2),
    &__top-cards:nth-of-type(3) {
      width: 100%;
      align-items: flex-start;
      padding: 1rem;
    }
  }
}
.payment-records-header {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1.25px;
  margin: 2rem 0 3rem 0;
  background: linear-gradient(93.88deg, #49a8f8 6.88%, #0053f4 74.45%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.payment-records-item {
  font-size: 14px !important;
}

.client-management {
  position: relative;
  height: calc(110vh - 112px);
}

.client-management-control {
  @media only screen and (max-width: 450px) {
    gap: 10px;
  }
  @media (max-width: 745px) {
    margin: auto;
  }
  @media (min-width: 1000px) and (max-width: 1030px) {
    margin: auto;
  }
}
.client-analytics-card {
  border-radius: 20px !important;
  border: none;
  padding-left: 50px;
  padding-right: 50px;
  width: 452.49px;
  @media (max-width: 500px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: 855px) {
    width: 45%;
    padding-top: 0.5em !important;
    padding-bottom: 0.5em !important ;
  }
  @media (max-width: 745px) {
    width: 100%;
    margin-bottom: 12px;
  }
  @media (max-width: 760px) {
    padding-top: 0.7em !important;
    padding-bottom: 0.7em !important ;
  }

  @media (min-width: 1000px) and (max-width: 1030px) {
    width: 100%;
    margin-bottom: 14px;
  }

  .client-analytics-text {
    font-size: 20px;
    font-weight: 600;
    .first-text {
      @media (max-width: 760px) {
        display: none;
      }
    }
  }
  .client-count {
    font-size: 34px;
    font-weight: 600;
    color: #2062f4;
    @media (max-width: 855px) {
      font-size: 25px;
    }
  }
}
.client-icon-card {
  padding: 12px;
  border-radius: 10px;
  border: none;
  &.download {
    padding: 8px;
    margin: 0 15px;
  }
  &.list {
    padding: 10px;
  }
}
.client-inactive-state {
  margin-top: 123.48px;
  // width: 100%;
  // position: absolute;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  .client-inactive-state-card {
    margin: auto;
    width: 75%;
    padding: 54px 0;
    border-radius: 22px;
    border: none;
    @media (max-width: 425px) {
      width: 100%;
    }
    .client-inactive-header-text {
      font-size: 18px;
      font-weight: 600;
      @media (max-width: 450px) {
        font-size: 16px;
      }
    }
    .client-inactive-subhead-text {
      font-size: 13px;
      font-weight: 400;
      width: 35%;
      @media (max-width: 600px) {
        width: 70%;
      }
      @media (max-width: 450px) {
        font-size: 80%;
      }
    }
    .client-create-button {
      background: linear-gradient(
        93.88deg,
        #49a8f8 6.88%,
        #0053f4 74.45%
      ) !important;
      color: white;
      border-radius: 16px;
      margin: auto;
      width: 38%;
      padding: 0.855em;
      @media (max-width: 710px) {
        width: 85%;
      }
      @media (max-width: 600px) {
        width: 70%;
      }
      @media (max-width: 500px) {
        width: 100%;
      }
    }
  }
}
//   .client-ctrl-icon{
//       background: ff;
//   }
.create-button {
  background: #2062f4 !important;
  border-radius: 10px;
  padding: 10px 15px;
  // width: 213px;
  text-align: center;
  color: white;
  border: none;

  h6 {
    font-weight: 500;
    font-size: 14px;
  }
  .create-icon {
    margin-right: 10px;
    background: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
}
.add-client-wrapper-2 {
  margin-top: 15px;
  padding-left: 0;
  padding-right: 0;
  .user-icon-container {
    background: #e8f7ff;
    margin: auto;
    padding-top: 17px;
    width: 82px;
    height: 82px;
    border-radius: 50%;
  }
  .add-client-text {
    h3,
    h5 {
      font-size: 30px;
      font-weight: 700;
    }
    p {
      font-size: 13px;
      font-weight: 400;
      opacity: 0.8;
    }
  }
  input {
    border-radius: 16px !important;
    padding: 14px 20px;
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 300;
    // width:100%;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    @media (min-width: 1600px) {
      padding: 21px 24px !important;
    }
  }
  button {
    background: linear-gradient(93.88deg, #49a8f8 6.88%, #0053f4 74.45%);
    border-radius: 16px;
    color: #fff;
    border: none;
    padding: 12px;
  }
}

.white-button {
  background: #ffffff;
  border-radius: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 1.25px;
  color: #2062f4;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.grid-view {
  margin-top: 54.8px;
  .grid-view-card {
    padding: 18px 12px;
    border-radius: 20px;
    border: none;
    @media (min-width: 1600px) {
      padding: 25px 33px;
    }
    // width:268px;
  }

  .client-details {
    background: #f9fafc;
    border-radius: 10px;
    padding: 9px 12px;
    .client-logo-wrapper {
      margin-right: 26.54px;
    }
    .client-name {
      font-size: 14px;
      font-weight: 600;
    }
    .client-project {
      font-size: 12px;
      font-weight: 400;
    }
  }
  .project-details {
    margin-top: 20px;
    .project-timeline-header,
    .date-created-header {
      font-size: 12px;
      font-weight: 600;
      color: #04004d;
    }
    .project-timeline,
    .date-created {
      font-size: 12px;
      font-weight: 400;
    }
  }
  .tag-container {
    background: #e8f7ff;
    border-radius: 10px;
    padding: 8px 17.25px;
  }
  .tag-container-text {
    color: #2062f4;
    font-size: 12px;
    margin-left: 9px;
  }
}

.list-client-item-bold-finance {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  color: #9da8b6;
}

.list-client-item-finance {
  color: #9da8b6;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 0;
}

.list-client-delete-finance {
  color: #ed2e7e;
  background-color: rgba(217, 87, 87, 0.15) !important;
  border: none;
  font-size: 14px;
  padding: 0.5rem 0.8rem;
  border-radius: 10px !important;
}
.status-tag-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 6px;
}
.list-client-tag-paid {
  background: rgba(87, 217, 163, 15%) !important;
  color: #57d9a3;
  padding: 8px 12px;
  border-radius: 10px !important;
}
.list-client-tag-overdue {
  background: #f5ebed;
  color: #ff2154;
  padding: 8px 12px;
  border-radius: 10px !important;
}
.list-client-tag-pending {
  background-color: #f9eee2;
  padding: 8px 12px;
  color: #ed8000;
  border-radius: 10px;
}

.client-details {
  .client-profile-card {
    padding: 40px 70px 20px 40px;
    background: #e8f7ff;
    border: 1px solid #49a8f8;
    border-radius: 24px;
  }
  .client-name {
    font-size: 14px;
    font-weight: 600;
    // margin-bottom: 10px;
  }
  .client-project {
    font-size: 12px;
    font-weight: 400;
  }
  .project-timeline,
  .client-portal {
    h1 {
      color: #04004d;
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 13px;
    }
    p {
      color: #04004d;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 13px;
    }
  }
  .client-portal {
    p {
      color: #2062f4;
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 13px;
    }
  }
  .content-calendar {
    padding: 0 40px;
    background: #fff;
    border-radius: 24px !important;
    border: none !important;
    h2 {
      font-size: 20px;
      font-weight: 600;
      color: #04004d;
      margin-bottom: 26px;
    }
    .first-section {
      padding-top: 40px;
      padding-bottom: 40px;
      border-right: 1px solid #e8f7ff;
    }
    .second-section {
      padding: 12px 0px 0 30px !important;
      .month {
        font-size: 12px;
        font-weight: 400;
        color: #d3dce6;

        margin-bottom: 16.5px;
        &.active {
          background: linear-gradient(164.82deg, #2062f4 3.9%, #7337fd 100%);
          border-radius: 4px;
          padding: 8px;
        }
      }
    }
  }
  .client-basic-information,
  .client-invoices,
  .client-connected-account-card {
    padding: 40px;
    background: #fff;
    border-radius: 24px !important;
    border: none !important;
    .schedule-post-button {
      background: #2062f4 !important;

      border-radius: 10px;
      padding: 10px 20px;
      // width: 213px;
      text-align: center;
      color: white;
      border: none;

      h6 {
        font-weight: 500;
        font-size: 14px;
      }
      .post-icon {
        margin-right: 10px;
        background: #fff;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }
    h2 {
      font-size: 20px;
      font-weight: 600;
      color: #04004d;
      margin-bottom: 26px;
    }
    .client-information-item {
      background: #f9fafc;
      border-radius: 10px;
      padding: 10px 15px 10px 15px;
      margin-bottom: 20px;
      h2 {
        color: #6e7191;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
      }
      // .information-icon {
      //   border: #fff 1px solid;
      //   // border-radius: 10px;
      // }
    }
  }
  .client-accounts {
    .account {
      background: #f9fafc;
      border-radius: 10px;
      padding: 20px 30px;
      margin-bottom: 25px;
      p {
        font-size: 15px;
        font-weight: 400;
        color: #6e7191;
        margin-bottom: 0;
        margin-right: 10px;
      }
      img {
        margin-right: 13px;
      }
      // margin-right: 26px;
    }
  }
}

.invoice-data-item {
  color: #9da8b6;
  font-size: 12px;
  font-weight: 300;
}
.invoice-status {
  background: rgba(87, 217, 163, 0.15);
  border-radius: 10px;
  padding: 10px 19px;
  color: #57d9a3;
  font-weight: 500;
  font-size: 12px;
}
.invoice-data-item-bold {
  font-size: 12px;
  font-weight: 600;
  color: #9da8b6;
}
.create-invoice-button {
  font-weight: 600 !important;
}
.client-details-settings-modal {
  margin: auto;
  width: 75%;
  padding: 50px 0;
  h1 {
    font-size: 30px;
    font-weight: 700;
  }
  p {
    font-size: 13px;
    font-weight: 400;
  }
  input {
    font-weight: 500;
    font-size: 14px;
    color: #6e7191;
    border-radius: 16px;
    padding: 21px 24px;
    width: 100%;
    margin: 55px 0;
    border: 1px solid #e5ecf2;
    &:focus {
      border-color: 1px solid #2062f4;
    }
  }
  button {
    background: #2062f4;
    border-radius: 16px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
  }
}

.invoice-modal {
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 41px;
    text-align: right;
    letter-spacing: 0.374px;
    color: #2062f4;
  }
  &__light {
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.25px;
    color: #6f6f84;
  }
  &__bold {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: #222234;
  }
  &__status {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #00d67d;
    margin-left: 10px;
    text-transform: uppercase;
  }
  &__paid {
    color: #00d67d;
  }
  &__pending {
    color: #ed8000;
  }
  &__overdue {
    color: #dc3545;
  }
  &__grey-section {
    background: #fafafc;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 8px;
  }
  &__qty {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #6f6f84;
  }
  &__item {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    letter-spacing: 0.05em;
    color: #222234;
    white-space: nowrap;
  }
  &__blue-section {
    background: #e8f7ff;
    border-radius: 8px;
  }
  &__total {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 36px;
    text-align: right;
    letter-spacing: 0.36px;
    color: #2062f4;
  }
}

.chat {
  margin-top: auto;
  margin-bottom: auto;
}
.card {
  border-radius: 15px !important;
  background-color: white !important;
}

label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.35px;
  color: #111317;
}

.contacts_body {
  padding: 0.75rem 0 !important;
  overflow-y: auto;
  white-space: nowrap;
}
.msg_card_body {
  overflow-y: auto;
}
.card-header {
  border-radius: 15px 15px 0 0 !important;
  border-bottom: 0 !important;
  background-color: white !important;
}
.card-footer {
  border-radius: 0 0 15px 15px !important;
  border-top: 0 !important;
}
.container {
  align-content: center;
}
.search {
  border-radius: 15px 0 0 15px !important;
  background-color: white !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 1px solid #e5ecf2 !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #6b778c !important;
}
.search:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.type_msg {
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  height: 60px !important;
  overflow-y: auto;
}
.type_msg:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.attach_btn {
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.send_btn {
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.search_btn {
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.contacts {
  list-style: none;
  padding: 0;
}
.contacts li {
  width: 100% !important;
  padding: 5px 10px;
  margin-bottom: 15px !important;
  border-radius: 24px;
  color: rgba(4, 0, 77, 1) !important;
}
// .active{
//   background-color: #F9FAFC;
// }
.user_img {
  width: 55px;
  height: 60px;
  border: 1.5px solid #f5f6fa;
  background: #ffffff;
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.05);
  border-radius: 16px !important;
}
.user_img_msg {
  height: 40px;
  width: 40px;
  border: 1.5px solid #f5f6fa;
}
.img_cont {
  position: relative;
  height: 70px;
  width: 70px;
}
.img_cont_msg {
  height: 40px;
  width: 40px;
}
.online_icon {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #4cd137;
  border-radius: 50%;
  bottom: 0.2em;
  right: 0.4em;
  border: 1.5px solid white;
}
.offline {
  background-color: #c23616 !important;
}
.user_info {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}

.chat-titles {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  align-items: center;
  letter-spacing: 0.35px;
  color: #111317;
  padding: 10px 0px;
}
.user_info span {
  color: rgba(4, 0, 77, 1);
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #04004d;
}
.user_info p {
  color: rgba(4, 0, 77, 1);
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.04px;
}
.video_cam {
  margin-left: 50px;
  margin-top: 5px;
}
.video_cam span {
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
}
.msg_cotainer {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  background: #f7f7f7;
  border-radius: 8px;
  padding: 10px 10px 0px;
  position: relative;
}
.msg_cotainer h6 {
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: flex-end;
  color: #1b1a57;
}

.msg_cotainer_send {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  background: #2f80ed;
  border-radius: 8px 8px 0px 8px;
  padding: 10px 10px 0px;
  position: relative;
}
.msg_cotainer_send h6 {
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: flex-end;
  color: #ffffff;
}

.msg_time {
  color: #a1a1bc;
  font-size: 10px;
  position: relative;
  right: 0;
}
.msg_time_send {
  bottom: 0px;
  color: #ffffff;
  font-size: 10px;
  position: relative;
  right: 0;
}
.msg_head {
  position: relative;
}
#action_menu_btn {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  cursor: pointer;
  font-size: 20px;
}
.action_menu {
  z-index: 1;
  position: absolute;
  padding: 15px 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 15px;
  top: 30px;
  right: 15px;
  display: none;
}
.action_menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.action_menu ul li {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 5px;
}
.action_menu ul li i {
  padding-right: 10px;
}
.action_menu ul li:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}
@media (max-width: 576px) {
  .contacts_card {
    margin-bottom: 15px !important;
  }
}

.bank-select {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 16px;
}

.off-canvas-menu {
  position: fixed;
  // background-color: rgba(0,0,0,.75);
  z-index: 200;
  right: 0;
  top: 10px;
  height: 100vh;
  width: 85vw;
  background: rgba(29, 30, 44, 0.32);
  backdrop-filter: blur(10px);
  border-top-left-radius: 30px;
  @media screen and (max-width: 999px) {
    width: 100vw;
    border-top-left-radius: 0px;
  }
  &__content {
    background-color: white;
    min-height: 90vh;
    height: 100%;
    width: 42vw;
    overflow-y: auto;
    position: fixed;
    right: 0;
    &::-webkit-scrollbar {
      width: 1em !important;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important ;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey !important;
      outline: 1px solid slategrey !important;
    }
    h6 {
      font-size: 0.8rem !important;
    }

    .form-section {
      height: 80%;
      overflow-y: "auto";
      overflow-x: "hidden";
      // position: relative;
      padding: 0 1rem;
      &::-webkit-scrollbar {
        width: 1em !important;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important ;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
      }

      &::-webkit-scrollbar-thumb {
        background-color: darkgrey !important;
        outline: 1px solid slategrey !important;
      }

      @media screen and (max-width: 450px) {
        padding: 0;
        .items-section {
          .row > * {
            padding-left: 0 !important;
          }
        }
      }
    }

    @media screen and (max-width: 450px) {
      width: 95vw;
    }
  }
  &__input {
    border: 1px solid #e0e0e0 !important;
    box-sizing: border-box !important;
    border-radius: 16px !important;
    font-size: 0.8rem !important;
  }
  .add-item-btn {
    font-size: 14px;
    font-weight: 700;
    color: #2062f4;
    cursor: pointer;
    span {
      font-size: 15px;
      background-color: #2062f4;
      color: #fff;
      padding: 0 2px;
      margin-right: 0.3rem;
      border-radius: 4px;
    }
  }
  .form-control {
    font-size: 0.8rem;
  }
  textarea {
    font-size: 14px;
  }
}

.add-item {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1.25px;
  color: #2062f4;
}

.save-pdf {
  border: 1px solid #2062f4;
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1.25px;
  color: #2062f4;
  height: 40px;
  background-color: #fff;
}
.recurring {
  background: #2062f4;
  border: transparent;
  border-radius: 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1.25px;
  color: #f7f7fc;
  cursor: pointer;
}
.send {
  background: #2062f4;
  border: transparent;
  border-radius: 10px !important;
  font-weight: bold;
  font-size: 14px !important;
  line-height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1.25px;
  color: #f7f7fc;
  cursor: pointer;
  height: 40px;
  background-position: center;
  transition: background 0.8s;
}
/* Ripple effect */
.send:hover,
.recurring:hover {
  background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%)
    center/15000%;
}
.send:active,
.recurring:active {
  background-color: #6eb9f7;
  background-size: 100%;
  transition: background 0s;
}
.send:disabled {
  background-color: #ccc;
}
.blue-btn {
  background-color: transparent;
  border: 1px solid #2062f4;
  border-radius: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  text-align: center;
  letter-spacing: 1.25px;
  color: #2062f4;
  cursor: pointer;
}

.slightly-black {
  color: #17151e;
  font-size: 14px !important;
  line-height: 24px;
}

.light-blue-bg {
  background: #e8f7ff;
  border-radius: 8px;
}

.more-actions-container {
  position: absolute;
  top: 6%;
  left: 50%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 50px #ccc;
}

.action-menu {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: #575a65;
  cursor: pointer;
}

.action-menu:hover {
  background: #f9fafc;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  line-height: 22px;
  // padding: 0.5rem 0;
  letter-spacing: 0.25px;
  color: #2062f4;
}

.canvas-textarea {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 16px;
}

.scheduled-post {
  &__menu {
    &__container {
      border-bottom: 1px solid #e5ecf2;
    }
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #111317;
    cursor: pointer;
  }
  &__active {
    color: #49a8f8;
    border-bottom: 3px solid #49a8f8;
  }
  &__filters {
    background: #e8f7ff;
    border-radius: 5px;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.04px;
    color: #111317;
  }
  &__filters-2 {
    background: #e5ecf2;
    border-radius: 5px;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.04px;
    color: #111317;
  }
  &__search {
    border: 1px solid #c0ccda;
    box-sizing: border-box;
    border-radius: 5px;
    input {
      border: none;
    }
  }
  &__table {
    &__header {
      &__container {
        background: #f9fafc;
        &__title {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          align-items: center;
          letter-spacing: 0.04px;
          color: #111317;
        }
      }
    }
  }
  &__post {
    &__new {
      &__steps {
        &__container {
          border-bottom: 1px solid #e5ecf2;
        }
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #c0ccda;
        opacity: 0.8;
        &__active {
          color: #032041;
        }
        &_number {
          background: #c0ccda;
          border: 3px solid #ffffff;
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #ffffff;
          border-radius: 50%;
          &__active {
            background: linear-gradient(
              180deg,
              #6ad5ed 0%,
              #49a8f8 100%
            ) !important;
            border: 3px solid #ffffff !important;
          }
        }
      }
      &__textarea {
        background: #ffffff;
        border: 1px solid #c0ccda;
        box-sizing: border-box;
        border-radius: 20px;
      }
      &__social_channel {
        background: #f9fafc;
        border-radius: 10px;
        &__text {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          display: flex;
          align-items: center;
          letter-spacing: 0.25px;
          color: #6e7191;
        }
      }
    }
    &__input {
      &__info-text {
        font-weight: normal;
        font-size: 12px;
        line-height: 22px;
        display: flex;
        align-items: center;
        letter-spacing: 0.25px;
        color: #2062f4;
      }
    }
    &__title {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.25px;
      color: rgba(17, 19, 23, 0.8);
    }
    &__user {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.25px;
      color: rgba(17, 19, 23, 0.8);
    }
    &__published {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.04px;
      color: #57d9a3;
    }
    &__date {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      text-align: left;
      letter-spacing: 0.04px;
      color: #111317;
    }
  }
}

.rounded-container {
  border-radius: 20px;
}

.gray-bg-button {
  background: rgba(9, 30, 66, 0.04);
  mix-blend-mode: normal;
  border-radius: 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  color: #a5adba;
  margin: 0px 10px;
  cursor: pointer;
}

.blue-bg-button {
  background: #2062f4;
  border-radius: 3px;
  mix-blend-mode: normal;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin: 0px 10px;
  cursor: pointer;
}

.green-info-text {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.04px;
  color: #00d67d;
}

.social-tabs {
  background: #ffffff;
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #6e7191;
  cursor: pointer;
}

.social-tabs-active {
  background: #e8f7ff;
  border: 1px solid #49a8f8;
  border-radius: 10px;
}

.bg-gray {
  background: #f9fafc;
  border-radius: 10px;
}

.example-text {
  background: #e8f7ff;
  border-radius: 10px;
  h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.04px;
    color: #111317;
  }
}

.modal-text-description {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.04px;
  color: #111317;
  text-align: left;
}

.blue-gradient-button {
  background: linear-gradient(93.88deg, #49a8f8 6.88%, #0053f4 74.45%);
  border-radius: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.75px;
  color: #f7f7fc;
}

.blue-border-btn {
  background: #ffffff;
  border: 1px solid #2062f4;
  box-sizing: border-box;
  border-radius: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.75px;
  color: #2062f4;
}

.btn-secondary {
  background-color: transparent !important;
  // border-color: transparent !important;
}
.btn-secondary:focus {
  outline: none !important;
  box-shadow: none !important;
}
.status {
  color: #6f6f84;
  margin-top: 1rem;
}
.phone-selectdropdown {
  width: 35% !important;
  font-size: 14px !important;
  z-index: 1000 !important;
  border-radius: 16px !important;
  // border-bottom-right-radius: 12px !important;
}
.phone-inputField {
  margin-left: -2rem;
  border-radius: 16px !important;
  padding: 0 2.8rem !important;
}
.phone-inputField:focus {
  border-color: #ced4da !important;
  box-shadow: none !important;
}

@media (max-width: 745px) {
  .invoice-buttons {
    flex-direction: column;
  }
}
