.settings {
  .settings-card {
    border-radius: 24px;
    border: none;
    padding: 35px 24px;
    @media screen and (max-width: 450px) {
      padding: 35px 12px;
    }
    .upload-text-wrapper {
      margin-left: 13px;
      margin-top: 5px;
      h1 {
        font-weight: 600;
        font-size: 20px;
        color: #111317;
        opacity: 0.8;
      }
      p {
        font-size: 14px;
        color: #17151e;
        opacity: 0.8;
      }
    }
    .personal-details-form-card,
    .business-details-form-card {
      padding: 29px 60px;
      border: 1px solid #c0ccda;
      border-radius: 20px;

      @media screen and (max-width: 450px) {
        padding: 29px 20px;
      }

      h1 {
        font-size: 20px;
        margin-bottom: 25px;
      }
      input:not([type="checkbox"]):not([type="button"]):not([type="search"]) {
        border: 1px solid #49a8f8;
        border-radius: 16px;
        padding: 21px 24px;
        margin-bottom: 19px;
      }
      .ant-select-selector {
        .ant-select-selection-search {
          bottom: -20px !important;
          .ant-select-selection-search-input {
            height: 48px !important;
            margin-top: 6px;
          }
        }
      }
      .css-1s2u09g-control {
        padding: 16px;
        border-radius: 16px;
      }
      button {
        background: #2062f4;
        border-radius: 10px;
        border: none;
        padding: 14px 0;
      }
    }
    .invoice-form {
      padding: 29px 60px;
      border-radius: 20px;

      @media only screen and (max-width: 768px) {
        padding: 0;
      }

      h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.36px;
        color: #000000;
      }
      label {
        display: block;
        color: #6e7191;
        padding: 0.5rem 0;
      }
      input:not([type="checkbox"]):not([type="button"]):not([type="search"]),
      input:focus,
      select,
      textarea,
      select:focus-visible {
        border: 1px solid #49a8f8;
        outline-color: #49a8f8;
        border-radius: 16px;
        padding: 17px 24px;
        margin-bottom: 15px;
      }
      .css-1s2u09g-control {
        padding: 16px;
        border-radius: 16px;
      }
      button {
        background: #2062f4;
        border-radius: 10px;
        border: none;
        padding: 14px 0;
        margin-top: 1rem;
        width: 17%;
        @media only screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
    .img-container {
      background: #e0e6ed;
      border-radius: 10px;
      width: 122.27px;
      height: 116px;
    }
    .upload-picture-container {
      padding: 24px 28px;
      border: 3px dashed #e5e9f2;
      border-radius: 24px;
      width: 100%;
      h1 {
        font-size: 20px;
        margin-bottom: 25px;
      }
      @media screen and (max-width: 450px) {
        h1 {
          margin-bottom: 10px;
        }
      }
    }
  }

  .password-container {
    position: relative;
    cursor: pointer;
    .password-icon {
      position: absolute;
      top: 18px;
      right: 15px;
    }
  }
  .billing-section {
    .billing-text {
      h1 {
        font-size: 34px;
      }
      p {
        font-size: 14px;
        color: #17151e;
        opacity: 0.8;
      }
    }
    .billing-card {
      background: #e0e0e050;
      // border: 0.1px solid #c0ccda;
      border-radius: 20px;
      height: 230px;
      padding: 1.5rem;
      // box-shadow: -189px 200px 110px rgb(209 209 209 / 1%),
      //   -106px 112px 93px rgb(209 209 209 / 3%),
      //   -47px 50px 69px rgb(209 209 209 / 9%),
      //   -12px 12px 38px rgb(209 209 209 / 10%),
      //   6px 6px 6px rgb(209 209 209 / 10%);
      .plan-name {
        color: #17151e;
        span {
          font-size: 14px;
        }
        p {
          font-size: 20px;
          font-weight: 600;
        }
      }
      .plan-amount {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.36px;
        color: #17151e;
        .plan-duration {
          font-weight: 600;
          font-size: 12px;
          line-height: 28px;
          letter-spacing: 0.35px;
          color: #231f2080;
        }
      }

      .plan-description {
        font-weight: 400;
        font-size: 11px;
        line-height: 22px;
        letter-spacing: 0.35px;
        color: #17151e;
        margin-bottom: 1rem;

        @media screen and (max-width: 450px) {
          line-height: 16px;
        }
      }
      .card-actions {
        margin-top: 1.5rem;
      }
      .btn-no-bg {
        color: #0b6bff;
        font-size: 14px;
      }
      .btn-border-radius {
        border-radius: 10px;
        font-size: 14px;
      }
      .payment-card-title {
        font-size: 20px;
        font-weight: 600;
      }
      .payment-card-subtitle {
        font-size: 14px;
        color: #17151e !important;
      }
      .billing-info-card {
        background: #fff;
        border-radius: 24px;
        padding: 12px 14px;
        margin-bottom: 2px;
        opacity: 1 !important;
        .card-info {
          font-size: 14px;
          opacity: 0.6;
          font-weight: 400;
          color: #c0ccda;
          &.semi-bold {
            color: #17151e;
            opacity: 0.8;
          }
          @media screen and (max-width: 450px) {
            font-size: 12px;
          }
        }
        @media screen and (max-width: 450px) {
          padding: 12px 8px;
        }
      }
    }
  }
  .account-setup-card {
    padding: 51px 37px 110px 37px;
    background: #e0e0e040 !important;
    border: none;
    border-radius: 20px;
    button {
      margin-top: 20px;
      border-radius: 10px;
      background: #2062f4 !important;
      width: 70%;
      border-radius: 15px !important;
      border: none !important;
      font-weight: 700;
      padding: 14px 0;
      &:focus {
        outline: none !important;
        border: 1px solid rgba(32, 98, 244);
        box-shadow: unset;
      }
    }
    .billing-info-card {
      background: #fff;
      border-radius: 24px;
      padding: 35px 20px;
      margin-bottom: 12px;
      opacity: 1 !important;
      .card-info {
        font-size: 14px;
        opacity: 0.6;
        font-weight: 400;
        color: #c0ccda;
        &.semi-bold {
          color: #17151e;
          opacity: 0.8;
        }
      }
    }
  }

  .team-section {
    padding: 3rem;
    .header-section {
      h1 {
        font-size: 20px;
        margin: 0;

        @media screen and (max-width: 450px) {
          font-size: 14px;
        }
      }
    }
    @media screen and (max-width: 450px) {
      padding: 1rem 0rem;
    }
    .create-button {
      background: #2062f4 !important;
      // margin: auto;
      cursor: pointer;
      border-radius: 10px;
      padding: 10px 15px;

      text-align: center;
      color: white;
      border: none;

      h6 {
        font-size: 14px;
        margin: 0;

        @media screen and (max-width: 450px) {
          font-size: 12px;
        }
      }
      .create-icon {
        margin-right: 10px;
        background: #fff;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }

      @media screen and (max-width: 450px) {
        padding: 10px;
      }
    }
  }
  .icon-wrapper {
    border: solid 1px #fff;
    border-radius: 50%;
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.08);
  }
  .subscription-section {
    .left-section {
      .left-section-title {
        font-size: 24px;
        font-weight: 700;
        margin-top: 40px;
      }
      .subscription-details-card {
        background: #f9fafc !important;
        border-radius: 20px;
        padding: 54px 27px;
        border: none;
        .subscription-details-title {
          font-weight: 600;
          font-size: 20px;
        }
        .subscription-price-wrapper {
          padding: 30px 41px;
          background: #e8f7ff;
          border-radius: 20px;
          border: none;

          h1 {
            font-size: 71px;
          }
          .subcription-plan-name {
            font-size: 20px;
            font-weight: 700;
          }
          .subscription-price {
            font-size: 130px;
            color: #2062f4;
            margin-bottom: 0;
          }
        }
        button {
          border-radius: 10px;
          border: none;
          padding: 10px;
          margin: auto;
          width: 50% !important;
          margin-top: 17px;
          cursor: pointer;
          background: #fff;
          color: #414146 !important;
          &:focus {
            box-shadow: none;
          }
        }
        a {
          background: #2062f4;
          border-radius: 10px;
          border: none;
          padding: 10px;
          margin: auto;
          width: 80 !important;
          margin-top: 17px;
          text-decoration: none;
          text-align: center;
          cursor: pointer;
        }
        a:hover {
          color: #fff;
        }
      }
    }
    .right-section-container {
      padding-left: 0;
      .right-section-title {
        margin: 20px 24px 10px;
        font-size: 19px;
        color: #04004d !important;
        font-weight: 700;
      }
    }
  }
  .subscription-data-item {
    font-size: 12px;
    font-weight: 300;
    color: #9da8b6;
  }
  .subscription-data-item-bold {
    font-size: 12px;
    font-weight: 600;
    color: #9da8b6;
  }
  .subscription-status-paid {
    background: rgba(87, 217, 163, 0.15) !important;
    border-radius: 10px;
    color: #57d9a3;
    padding: 8px 12px;
    font-size: 12px;
    font-weight: 500;
  }
  .subscription-status-cancelled {
    background: rgba(11, 107, 255, 1) !important;
    border-radius: 10px;
    color: #2062f4;
    padding: 8px 12px;
    font-size: 12px;
    font-weight: 500;
  }
  .subscription-status-failed {
    background: rgba(255, 33, 84, 0.1) !important;
    border-radius: 10px;
    color: #ff2154;
    padding: 8px 12px;
    font-size: 12px;
    font-weight: 500;
  }
  .subscription-status-trial {
    background: #cacaca05 !important;
    border-radius: 10px;
    color: #cccc;
    padding: 8px 12px;
    font-size: 12px;
    font-weight: 500;
  }
}
.add-member-wrapper {
  margin-top: 15px;
  padding-left: 42px;
  padding-right: 42px;
  .user-icon-container {
    background: #e8f7ff;
    margin: auto;
    padding-top: 17px;
    width: 82px;
    height: 82px;
    border-radius: 50%;
  }
  .add-member-text {
    h3 {
      font-size: 30px;
      font-weight: 700;
    }
    p {
      font-size: 13px;
      font-weight: 400;
      opacity: 0.8;
    }
  }
  input:not([type="checkbox"]):not([type="button"]):not([type="search"]) {
    border-radius: 16px !important;
    padding: 14px 20px;
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 300;
    // width:100%;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    @media (min-width: 1600px) {
      padding: 21px 24px !important;
    }
  }
  button {
    background: #2062f4;
    border-radius: 16px;
    color: #fff;
    border: none;
    padding: 12px;
  }
}

.css-1s2u09g-control {
  padding: 0.5rem;
  border-radius: 16px !important;
}
