@mixin placeholder {
    &::-webkit-input-placeholder {
        @content
    }

    &:-moz-placeholder {
        @content
    }

    &::-moz-placeholder {
        @content
    }

    &:-ms-input-placeholder {
        @content
    }
}

.Button_Wrap {
    width: 100%;
    height: 64px;
    color: #FFFFFF;
    background: linear-gradient(93.88deg, #49A8F8 6.88%, #0053F4 74.45%);
    border-radius: 16px;
    border: none;
    outline: none;
    transition: 1s ease;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.75px;
    color: #F7F7FC;
    cursor: pointer;
    &:disabled{
        background: #dddddd;
        opacity: 0.4;
    }
}

.inputField_Wrap {
    background: #FFFFFF;
    border: 1px solid #49A8F8;
    border-radius: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    height: 64px;
    width: 100%;
    padding: 10px 20px;
    color: #333;
    &:disabled{
        border:1px solid #dddddd;
    }

    @include placeholder {
        font-size: 14px;
        color: #6E7191;
    }
}