@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

.schedule-post-wrapper {
  position: relative;

  .fee {
    @media (max-width: 400px) {
      display: none;
    }
  }

  .showclient-wrapper {
    display: none;
    margin: 1rem 0;

    .showclient {
      cursor: pointer;
      width: 100px;
      padding: 0.3rem;
      text-align: center;
      background: #2062f4;
      color: white;
      font-size: 12px;
      font-weight: 300;
      border-radius: 8px;
    }

    @media (max-width: 576px) {
      display: block;
    }
  }

  .postWrapper {
    height: 100%;

    .topnav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;

      h6 {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        display: flex;
        align-items: center;
        letter-spacing: 0.35px;
        color: #111317;
      }

      a {
        background: #2062f4;
        border-radius: 10px;
        text-decoration: none;
        padding: 5px 30px;
        cursor: pointer;

        h5 {
          font-weight: 700;
          font-size: 14px;
          line-height: 21px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 1.25px;
          color: #f7f7fc;
        }
      }
    }

    .socialChannelWrapper {
      background: #ffffff;
      border-radius: 20px;
      margin-top: 20px;
      padding: 30px;

      @media (max-width: 576px) {
        padding: 0px;
      }
    }
  }

  .row-post {
    display: flex;
    align-items: center;
    gap: 14px;
    width: 250px;

    @media (max-width: 576px) {
      flex-direction: column;
    }

    .post-image {
      background: #e5ecf2;
      border-radius: 10px;
      img, video {
        width: 125px;
        height: 87px;
        border-radius: 5px;
        object-fit: cover;
      }
    }

    .post-title-name {
      h2 {
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
      }

      .post-user {
        display: flex;
        align-items: center;
        gap: 10px;
        background: #f9fafc;
        border-radius: 20px;
        padding: 4px 21px 4px 4px;

        .post-user-img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #49a8f8;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        h2 {
          margin-top: 5px;
          font-weight: 400;
          font-size: 12px;
          letter-spacing: 0.25px;
          color: rgba(17, 19, 23, 0.8);
        }
      }
    }
  }

  .post-postType {
    font-weight: 400;
    //width: 100px;
    width: 60px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.04px;
    color: #111317;
  }

  .post-date-time {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.04px;
  }

  .post-status {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: -10px;

    div {
      width: 7px;
      height: 7px;
      border-radius: 50%;
    }

    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.04px;
      // @media(max-width: 576px) {
      //     font-size: 10px;
      // }
    }
  }

  .pending {
    color: #ff2154;

    div {
      background: #ff2154;
      width: 7px;
      height: 7px;
      border-radius: 50%;
    }
  }

  .published {
    color: #57d9a3;

    div {
      background: #57d9a3;
    }
  }

  .scheduled {
    color: #2062f4;

    div {
      background: #2062f4;
    }
  }

  .draft {
    color: #565c65;

    div {
      background: #565c65;
    }
  }

  .poststatus {
    display: flex;
    align-items: center;
    position: relative;
    //gap: 16px;
    height: 28px;
    background: #e8f7ff;
    border-radius: 5px;
    padding: 0 0 0 5px;
    width: 100%;

    .smallcircle {
      width: 18px;
      height: 18px;
      background: #ffffff;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      .post-status {
        margin-left: unset;

        div {
          width: 8px;
          height: 8px;
        }
      }
    }

    .selectpost {
      display: flex;
      align-items: center;
      //margin-left: -8px;
      margin-left: 8px;
      flex: 1;
      h6 {
        margin-top: 0;
        margin-bottom: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
      }

      .selectholder {
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;

        select {
          background-color: transparent;
          border: none;
          width: 100%;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          cursor: pointer;
          z-index: 2;
          background-image: unset;

          &:focus {
            outline: none !important;
            border: none !important;
          }
        }

        // select::-ms-expand {
        //     display: none;
        // }
      }
    }

    .mask {
      position: absolute;
      right: 6px;
      z-index: 1;

      @media (max-width: 576px) {
        right: 130px;
      }
    }
  }

  .smallcalendar {
    width: 25px;
    height: 22px;
    background: #ffffff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .postime {
    display: flex;
    width: 310px;
    height: 28px;
    align-items: center;
    position: relative;
    background: #e5ecf2;
    border-radius: 5px;
    gap: 16px;
    padding: 0px 7px;
    cursor: pointer;

    @media (max-width: 576px) {
      width: 100%;
    }

    .smallcalendar {
      width: 25px;
      height: 20px;
      background: #ffffff;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .timepost {
      display: flex;
      width: 100%;

      h6 {
        margin-top: 8px;
        font-weight: 400;
        font-size: 11px;
        font-weight: 400;
        color: #111317;
        line-height: 18px;
        display: flex;
        align-items: center;
      }

      .timeholder {
        display: flex;
        align-items: center;
      }
    }

    // .calendar {
    //             position: absolute;
    //              right: 10px;
    //             @media(max-width: 576px) {
    //                 right: 60px;
    //             }
    //         }
  }

  .datepickerholder {
    position: absolute;
    left: 0;
    //width: 100%;
    z-index: 1;
  }

  .inputholder {
    position: relative;
    height: 33px;

    @media (max-width: 576px) {
      margin-top: 5px;
    }

    .input {
      border: 1px solid #c0ccda !important;
      border-radius: 5px !important;
      width: 145px;
      height: 100%;
      padding: 5px 30px;

      @media (max-width: 576px) {
        width: 100%;
      }

      &:focus {
        outline: none !important;
      }
    }

    img {
      position: absolute;
      top: 7px;
      left: 10px;
    }
  }
}

.clientWrapper {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 15px;
  height: 100%;

  .scheduleInputWrapper {
    position: relative;
    padding-bottom: 10px;

    input {
      background-color: transparent;
      border: none !important;
      border-bottom: 1px solid #e5ecf2 !important;
      padding: 5px 0px 5px 20px;
      padding-bottom: 1rem;
      width: 100%;

      &:focus {
        outline: none !important;
        border: none !important;
        border-bottom: 1px solid #e5ecf2 !important;
      }

      @include placeholder {
        font-size: 14px;
        font-weight: 400;
      }
    }

    img {
      position: absolute;
      top: 4px;
      left: -50px;
    }
  }

  .client-owner {
    display: flex;
    align-items: center;
    border-radius: 24px;
    list-style: none;
    padding: 15px 10px;
    justify-content: space-between;
    background: #f9fafc;

    @media (max-width: 576px) {
      width: 100%;
    }

    .client-owner-name {
      display: flex;
      align-items: center;
      gap: 8px;

      .clientowner-IconHolder {
        width: 55px;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.05);
        border-radius: 16px;

        img {
          width: 40px;
          object-fit: contain;
        }
      }

      .clientowner-NameHolder {
        color: #04004d;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 10px;

        h6 {
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0.1px;
          margin: 0;
        }

        p {
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.04px;
        }
      }
    }
  }

  .clientsHolder {
    display: flex;
    flex-direction: column;
    gap: 10px;
    ul {
      list-style: none;
      padding: 0px;

      .client-chart {
        display: flex;
        align-items: center;
        border-radius: 24px;
        list-style: none;
        padding: 15px;
        margin-bottom: 10px;
        justify-content: space-between;
        background-color: #f9fafc;

        .name_logo_holder {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        cursor: pointer;

        &.active {
          background-color: #f9fafc;
        }

        @media (max-width: 576px) {
          width: 100%;
        }

        .chartIconHolder {
          width: 55px;
          height: 55px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #ffffff;
          box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.05);
          border-radius: 16px;

          img {
            width: 40px;
            object-fit: contain;
          }
        }

        .clientNameHolder {
          color: #04004d;
          display: flex;
          flex-direction: column;
          justify-content: center;

          h6 {
            font-weight: 600;
            font-size: 13px;
            line-height: 22px;
            letter-spacing: 0.1px;
            margin: 0;
          }

          p {
            font-weight: 400;
            font-size: 10px;
            line-height: 18px;
            color: #2062f4;
          }
        }
      }

      .no-clients {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 0px;

        p {
          max-width: 184px;
          margin: auto;
          font-weight: 400;
          font-size: 12px;
          line-height: 21px;
          letter-spacing: 0.374px;
          color: #000000;
          text-align: center;
        }

        .addclient {
          margin-top: 20px;
          border: none !important;
          background: #2062f4 !important;
          border-radius: 10px !important;
          color: white !important;
          font-weight: 700 !important;
          font-size: 12px !important;
          padding: 10px 30px 10px 20px !important;
          display: flex;
          align-items: center;
          gap: 20px;
          cursor: pointer;

          .plus {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: solid;
            background: #ffffff;
            border-radius: 50%;
            color: #2062f4;
            font-size: 10px;
          }
        }
      }
    }
  }
}
