.auth {
  height: 100%;
  background-color: #f9fafc;
  // position: relative;
  .auth-logo {
    padding-top: 30px;
    margin-left: 69px;
    @media (max-width: 600px) {
      padding-top: 30px;
      margin-left: 30px;
    }
  }
  // input,
  // .css-1s2u09g-control {
  //   border: 1px solid #49a8f8 !important;
  // }

  input:not([type="checkbox"]):not([type="button"]):not([type="search"]),
  .css-1s2u09g-control {
    border-radius: 16px !important;
    padding: 12px 20px;
    font-size: 16px;
    margin-bottom: 17.5px;
    font-weight: 300;
    border: 1px solid #49a8f8 !important;
    @media (min-width: 1600px) {
      padding: 24px 21px !important;
    }

    @media (max-width: 767px) {
      padding: 20px 21px !important;
      margin-bottom: 20px;
    }
    @media (max-width: 450px) {
      padding: 18px !important;
      margin-bottom: 15px;
    }
    &:focus {
      outline: none !important;
      // border: 1px solid rgba(32, 98, 244);
      box-shadow: unset;
    }
  }
  .red-border {
    border: 1px solid red !important;
  }
  input::placeholder,
  .css-1s2u09g-control::placeholder {
    color: #6e7191 !important;
    font-size: 14px;
    font-weight: 500;
  }
  .css-1gtu0rj-indicatorContainer svg {
    display: none;
  }

  .card {
    border: none;
    box-shadow: -20px 4px 30px rgba(0, 0, 0, 0.03);
  }
  .no-account {
    background: rgba(239, 242, 247, 1);
    border-radius: 24px;
    p {
      font-size: 16px;
      font-weight: 300;
      a {
        text-decoration: none;
        color: rgba(115, 55, 253, 1);
        font-size: 16px;
        margin-left: 5px;
        font-weight: 500;
      }
    }
  }
  .signin-left {
    height: 100vh;
    position: fixed;
    width: 35%;
    padding: 50px 0px;
    background: url("../img/loginec1.svg") left -30px top 160px no-repeat,
      url("../img/loginec2.svg") left -110px bottom -30px no-repeat,
      linear-gradient(170.87deg, #008aff 6.93%, #1791f9 42.4%, #00a6ff 61.41%),
      linear-gradient(93.88deg, #49a8f8 6.88%, #0053f4 74.45%);
    @media (max-width: 950px) {
      display: none;
    }

    @media (min-width: 1600px) {
      background: url("../img/loginec1.svg") left 0px top 441px no-repeat,
        url("../img/loginec2.svg") left 0px bottom -95px no-repeat,
        linear-gradient(180deg, #00fdf3 0%, #0053f4 120%);
      background-size: 40%, 85%, 100%;
    }

    .signin-img-container {
      background: white;
      border-radius: 37px;
      // width:437px;
      margin: auto;
      width: 75%;
      display: flex;
      //   height: 50vh;
      padding: 20px;
      justify-content: center;
      align-items: center;
      overflow: hidden; // height: 50vh;

      img {
        object-fit: cover;
        flex-shrink: 0;
        width: 100%;
        border-radius: 37px;
        box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.15);
        // min-width: 100%;
        // min-height: 90%;
      }
    }

    .signin-text {
      position: absolute;
      top: 22%;
      left: 15%;
      .signin-header-text {
        font-size: 48px;
        font-weight: 700;
        width: 65%;
        @media (max-width: 1600px) {
          width: 100%;
        }
      }
      .signin-subhead-text {
        font-weight: 400;
        // padding-right: 5em;
        font-size: 14px;
        width: 100%;
        // @media (max-width: 1600px) {
        //   width: 80%;
        // }
      }
    }
  }

  .signin-right {
    // height: 100%;
    padding: 55px 0 0;
    // position: relative;
    @media (max-width: 1000px) {
      padding: 10px 0;
    }

    .signin-logo {
      // display: none;
      // @media (max-width: 1000px) {
      //   display: block;
      // }
      display: block;
    }
    @media (min-width: 1600px) {
      position: relative;
      // padding: 110px 173px 72px;
    }
    .signin-form-wrapper {
      // display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 55%;
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      @media (max-width: 260px) {
        width: 90% !important;
      }
      @media (max-width: 600px) {
        width: 85%;
      }
    }
    .signin-form-card {
      .css-6j8wv5-Input {
        @media (min-width: 1200px) {
          padding: 8px 8px;
          // margin: 10px 0 45px;
        }
      }

      border-radius: 24px;
      h4 {
        font-size: 20px;
        font-weight: 400;
      }
      @media (max-width: 476px) {
        padding: 20px 15px !important;
        // margin: 10px 0 45px;
        h4 {
          font-size: 20px;
          font-weight: 600;
        }
      }
      @media (min-width: 1600px) {
        // padding: 74px 112px !important;
        margin: 10px 0 45px;
      }
      input:not([type="checkbox"]):not([type="button"]):not([type="search"]) {
        border-radius: 16px !important;
        padding: 18px 20px;
        font-size: 16px;
        margin-bottom: 20px;
        font-weight: 300;
        // width:100%;
        border: 1px solid #ced4da;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        @media (min-width: 1600px) {
          padding: 21px 24px !important;
        }
        &:focus {
          outline: none !important;
          border: 1px solid rgba(32, 98, 244);
          box-shadow: unset;
        }
      }
      .ant-select-selector {
        height: 55px !important;
      }

      a.login-link {
        padding: unset !important;
        margin: unset !important;
        text-decoration: none !important;
        color: #fff;
      }

      .input-check {
        margin-right: 10px;
      }
      .forgot-password {
        font-size: 14px;
        font-weight: 300;
        .forgot-password-link {
          text-decoration: none;
          color: rgba(45, 202, 115, 1);
          font-size: 14px;
          margin-left: 5px;

          //   font-weight: 500;
        }
      }
      //   .forgot-password {
      //     color: rgba(45, 202, 115, 1);
      //   }
      p {
        font-weight: 300;
        font-size: 16px;
        color: rgba(82, 87, 92, 1);
      }
      button {
        margin-top: 20px;
        background: linear-gradient(93.88deg, #49a8f8 6.88%, #0053f4 74.45%);
        border-radius: 16px;
        width: 100%;
        border: none;
        padding: 20px;
        @media (min-width: 1600px) {
          margin: 28px 0 32px;
        }
      }

      .easy-login {
        overflow: hidden;
        text-align: center;
        @media (min-width: 1600px) {
          margin-bottom: 24px;
        }
        @media (min-width: 325px) {
          margin-bottom: 0;
        }
        &:before,
        &:after {
          background-color: #e5e9f2;
          content: "";
          display: inline-block;
          height: 1px;
          position: relative;
          vertical-align: middle;
          width: 50%;
        }
        &:before {
          right: 0.5em;
          margin-left: -50%;
        }

        &:after {
          left: 0.5em;
          margin-right: -50%;
        }
      }

      //   .easy-login {
      //     width: 100%;
      //     text-align: center;
      //     border-bottom: 1px solid #000;
      //     line-height: 0.1em;
      //     margin: 10px 0 20px;
      //     span {
      //       background: #fff;
      //       padding: 0 10px;
      //     }
      //   }
    }
    .no-account {
      @media (min-width: 1600px) {
        padding: 35px 0 !important;
      }
    }
  }

  .signup-left {
    height: 100vh;
    position: fixed;
    width: 25%;
    padding: 68px 20px;
    // background: url("../img/register1.svg") left 0px top -55px no-repeat,
    //   url("../img/register2.svg") right 20px top 0px no-repeat,
    //   url("../img/register3.svg") left -110px bottom -30px no-repeat,
    //   linear-gradient(170.87deg, #008aff 6.93%, #1791f9 42.4%, #00a6ff 61.41%),
    //   linear-gradient(93.88deg, #49a8f8 6.88%, #0053f4 74.45%);
    background: url("../img/register.svg");
    @media (max-width: 991px) {
      display: none;
    }

    @media (min-width: 1600px) {
      background: url("../img/register1.svg") left 0px top -55px no-repeat,
        url("../img/register2.svg") right 20px top -145px no-repeat,
        url("../img/register3.svg") left -110px bottom -30px no-repeat,
        linear-gradient(170.87deg, #008aff 6.93%, #1791f9 42.4%, #00a6ff 61.41%),
        linear-gradient(93.88deg, #49a8f8 6.88%, #0053f4 74.45%);
      background-size: 100%;
    }
    .signup-text {
      width: 75%;
      h2 {
        font-size: 48px;
        font-weight: bold;
        letter-spacing: 0.374px;
      }
      p {
        margin-top: 51px;
        font-weight: 200;
        font-size: 14px;
        @media (min-width: 1600px) {
          font-weight: 200;
          font-size: 18px;
        }
      }
    }
  }
  .signup-right {
    .css-6j8wv5-Input {
      margin: 0;
    }
    .css-319lph-ValueContainer {
      padding: 0.25em 8px !important;
    }
    .css-tlfecz-indicatorContainer,
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    .css-6j8wv5-Input {
      @media (min-width: 767px) {
        // padding: 65px 87px 90px !important;
        padding: 5px !important;
      }
      @media (min-width: 4500px) {
        // padding: 65px 87px 90px !important;
        padding: 3px !important;
      }
    }
    .css-1s2u09g-control {
      border-radius: 16px;

      @media (min-width: 1200px) {
        // padding: 65px 87px 90px !important;
        padding: 7px !important;
      }
    }
    // position: absolute;
    // left: 0;
    // top: 0;
    // transform: translate(-50%, -50%);
    margin: 45px 18px;

    .signin-logo {
      display: none;
      @media (max-width: 991px) {
        display: block;
      }
    }
    @media (min-width: 1200px) {
      // padding: 65px 87px 90px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
      margin: 0;
    }
    @media (max-width: 767px) {
      display: flex;
      margin: auto;
      width: 75%;
    }
    @media (max-width: 450px) {
      margin: auto;
      width: 90%;
    }
    input:not([type="checkbox"]):not([type="button"]):not([type="search"]) {
      border-radius: 16px !important;
      padding: 18px 20px;
      font-size: 16px;
      margin-bottom: 20px;
      font-weight: 300;
      // width:100%;
      border: 1px solid #ced4da;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      @media (min-width: 1600px) {
        padding: 21px 24px !important;
      }
      &:focus {
        outline: none !important;
        border: 1px solid rgba(32, 98, 244);
        box-shadow: unset;
      }
    }
    .ant-select-selector {
      height: 55px !important;
      border-radius: 16px !important;
      font-size: 16px;
      border: 1px solid #49a8f8 !important;
      padding: 5px 11px !important;
      .ant-select-selection-search {
        bottom: -20px !important;
        .ant-select-selection-search-input {
          height: 48px !important;
        }
      }
      .ant-select-selection-placeholder {
        color: #636c72;
        font-size: 14px;
        font-weight: 600;
      }
    }

    .signup-form-wrapper {
      width: 90%;
      .card {
        box-shadow: -20px 4px 30px rgba(0, 0, 0, 0.03);
      }
      .wizard-control-buttons {
        button {
          font-size: 14px;
          padding: 6px 12px;
          border-radius: 3px;
        }
        button:disabled {
          border: 0;
          background: rgba(9, 30, 66, 0.04);
          mix-blend-mode: normal;
        }
        .btn-primary {
          background: #2062f4;
          border: none;
        }
      }
      .card-1 {
        @media (max-width: 767px) {
          display: none;
        }

        @media (min-width: 1600px) {
          padding: 113px 51px 51px 113px;
          margin: 0 -40px 0 0;
        }

        @media (min-width: 1200px) and (max-width: 1300px) {
          padding: 55px 40px 0 26px;
        }

        margin: 66px -40px 0 0;
        padding: 75px 40px 0 26px;
        border-radius: 24px;
        .rc-s .rc-steps-vertical .rc-steps-item-description {
          padding-bottom: 80px;
        }
        .rc-steps-item-content {
          margin-top: 0.5px;
        }
        .rc-steps-item-title {
          font-size: 17px;
          font-weight: 600;
          color: #111317 !important;
          @media (min-width: 1600px) {
            font-size: 24px;
            font-weight: 600;
          }
        }
        .rc-steps-vertical .rc-steps-item-description {
          font-weight: 400;
          color: #111317 !important;
          opacity: 0.8 !important;

          @media (min-width: 1600px) {
            font-size: 20px;
            font-weight: 400;
          }
        }

        // .rc-steps-item-wait .rc-steps-item-icon {
        //     color: #fff;
        //     background: linear-gradient(180deg, #6ad5ed 0%, #49a8f8 100%);

        // }
        // .rc-steps-item-wait .rc-steps-item-icon > .rc-steps-icon {
        //     color: #fff;
        //     border-color: none;

        // }
        .rc-steps-vertical .rc-steps-item-tail {
          &:after {
            background: linear-gradient(180deg, #6ad5ed 0%, #49a8f8 100%);
            width: 2px;
          }
        }
      }
      .signup-form-card {
        @media (max-width: 767px) {
          padding: 50px 70px !important;
        }
        @media (min-width: 1600px) {
          padding: 74px 83px 60px !important;
        }
        @media (max-width: 450px) {
          padding: 30px !important;
          margin: 0 0 2.5rem 0;
        }
        .cta {
          padding: 1rem 3rem;
          cursor: pointer;
          @media only screen and (max-width: 450px) {
            padding: 0 0 1rem 0;
          }
        }
        .password-icon {
          // position: absolute;
          top: 14px !important;
          @media (min-width: 1600px) {
            top: 20px !important;
          }
          @media (max-width: 760px) {
            top: 8px !important;
          }
          // right:15px !important;
        }
        // padding: 74px 111px !important;
        margin: 0;
        border-radius: 24px;
        .wizard-control-buttons {
          width: 58%;
          @media (max-width: 450px) {
            width: 100%;
          }
        }
        // .form {

        .dot {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          background-color: #e5e9f2;
          cursor: pointer;
          &.bg-primary {
            background-color: #2062f4;
          }
        }
        // }

        .business-form {
          button {
            background: linear-gradient(
              93.88deg,
              #49a8f8 6.88%,
              #0053f4 74.45%
            );
            border-radius: 16px;
            padding: 15px;
            border: none;
          }
        }
      }
    }
  }
  .reset-password {
    // height: 100vh;
    margin: auto;
    padding: 20px 0px;
    .reset-password-wrapper {
      input:not([type="checkbox"]):not([type="button"]) {
        border-radius: 16px !important;
        padding: 18px 20px;
        font-size: 16px;
        margin-bottom: 20px;
        font-weight: 300;
        // width:100%;
        border: 1px solid #ced4da;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        @media (min-width: 1600px) {
          padding: 21px 24px !important;
        }
        &:focus {
          outline: none !important;
          border: 1px solid rgba(32, 98, 244);
          box-shadow: unset;
        }
      }
      width: 40%;
      @media screen and (max-width: 1200px) {
        width: 60% !important;
      }
      // @media screen and (max-width: 530px) {
      //   width: 70% !important;
      // }

      @media screen and (max-width: 440px) {
        width: 90% !important;
      }
    }
    @media screen and (min-width: 1600px) {
      padding: 142px 0 102px;
    }
    .reset-password-text {
      h3 {
        color: #032041;
        font-size: 34px;
        letter-spacing: 0.374px;
        opacity: 0.8;
        @media screen and (max-width: 440px) {
          font-size: 22px;
        }
      }
      @media screen and (max-width: 440px) {
        p {
          display: contents;
        }
      }
      div {
        margin-top: 15px;
        margin-bottom: 30px;
        font-size: 16px;
        font-weight: 300;
        // width:77%;
        // margin: 15px auto 30px auto;
      }
    }

    .signin-form-card {
      padding: 3rem;
      @media screen and (max-width: 450px) {
        padding: 40px 20px !important;
      }
      border-radius: 24px;
      input {
        border-radius: 16px !important;
        padding: 18px 20px;
        font-size: 16px;
        margin-bottom: 20px;
        font-weight: 300;
        &:focus {
          outline: none !important;
          border: 1px solid rgba(32, 98, 244);
          box-shadow: unset;
        }
      }
    }
    //   .forgot-password {
    //     color: rgba(45, 202, 115, 1);
    //   }
    p {
      font-weight: 300;
      font-size: 16px;
      color: rgba(82, 87, 92, 1);
    }
    button {
      margin-top: 20px;
      background: linear-gradient(93.88deg, #49a8f8 6.88%, #0053f4 74.45%);
      border-radius: 16px;
      width: 100%;
      border: none;
    }
  }

  .password-container {
    position: relative;
    cursor: pointer;
    .password-icon {
      position: absolute;
      top: 14px;
      right: 15px;
    }
  }
  .signup-text {
    margin: auto;
    width: 50%;
  }
  .rc-steps-item-process .rc-steps-item-icon {
    background: linear-gradient(180deg, #6ad5ed 0%, #49a8f8 100%);
  }

  .rc-steps-horizontal .rc-steps-item-description {
    display: none;
  }

  .rc-steps-item-title {
    @media (max-width: 767px) {
      font-size: 20px;
      font-weight: 400;
    }
  }

  //plans
  .plan {
    background-color: #fff;
    border: 1px solid #d3dce6;
    border-radius: 20px;
    padding: 0.8rem;
    cursor: pointer;

    &__active {
      border: 1px solid #7337fd;
    }
    &__name {
      font-weight: 600;
      font-size: 20px;
      // lineHeight: "28px",
      letter-spacing: 0.35px;
      margin-bottom: 0;
      color: #17151e;
    }
    &__description {
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.35px;
      color: #17151e;
      margin-bottom: 0;
    }
    &__price-amount {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.36px;
      color: #17151e;
    }
    &__price-duration {
      font-weight: 600;
      font-size: 12px;
      line-height: 28px;
      letter-spacing: 0.35px;
      &_active {
        color: #00d67d;
      }
    }
    &__features {
      font-weight: 400;
      font-size: 11px;
      letter-spacing: 0.35px;
    }
  }
}

.rc-steps-item:last-child
  .rc-steps-item-container
  .rc-steps-item-content
  .rc-steps-item-description {
  width: 87% !important;
}
.rc-steps-item-container {
  margin-bottom: 2rem;
}
.rc-steps-item-process .rc-steps-item-icon {
  border: none !important;
  cursor: pointer;
}
.rc-steps-item-icon {
  margin-right: 10px !important;
}
.rc-steps-item-wait .rc-steps-item-icon {
  border: none !important;
  color: white !important;
  background: linear-gradient(180deg, #9da8b6 0%, #9da8b6 100%) !important;
  cursor: pointer;
}
.rc-steps-item-wait .rc-steps-item-icon > .rc-steps-icon {
  color: white !important;
}
@media (min-width: 768px) {
  .px-md-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
}
#react-select-2-input,
#react-select-3-input,
#react-select-4-input {
  // opacity: 0;
  width: 100%;
  border: none !important;
}
