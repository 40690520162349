@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

.circle {
  width: 29px;
  height: 29px;
  line-height: 25px;
  background: #c0ccda;
  border: 3px solid #ffffff;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -moz-background-clip: padding-box;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.stepWrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 0px 0px 20px 0px;
}

.stepBlock {
  &.circleWrapper {
    padding: 0px 20px;
    position: relative;
  }
}

.circleWrapper {
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 9px;
  color: rgba(192, 204, 218, 0.8);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.selected {
  & .circle {
    color: white;
    background: linear-gradient(180deg, #6ad5ed 0%, #49a8f8 100%);
    border: 3px solid #ffffff;
  }
}

.selected {
  & .circleWrapper {
    color: rgba(3, 32, 65, 0.8);
  }
}

.primaryButton {
  padding: 10px 25px;
  background: green;
  color: white;
  border: none;
  margin: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.stepHolder {
  background: #fff;
  border-radius: 20px;
  padding: 50px 30px;
  // width:304px;
  height: 100%;
  // border: solid purple;

  @media (max-width: 576px) {
    margin-bottom: 15px;
    width: 100%;
  }
}

.postStepContent {
  background: #fff;
  border-radius: 20px;
  padding: 30px;
  height: 100%;
  @media (max-width: 576px) {
    margin-top: 16px;
    height: auto;
  }
}

.postTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: rgba(3, 32, 65, 0.8);
  text-align: center;
  margin-bottom: 35px;
}
.postDetails {
  width: 350px;
  margin: auto;
  padding-bottom: 200px;

  @media (max-width: 576px) {
    width: 100%;
  }
}

.socialChannels {
  gap: 20px;
  padding-bottom: 200px;
  .content {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 400px;
  }

  @media (max-width: 576px) {
    width: 100%;
  }

  .instagramCheckbox {
    display: flex;
    align-items: center;
    gap: 15px;
    background: #f9fafc;
    border-radius: 10px;
    padding: 5px 15px;
    position: relative;

    @media (max-width: 576px) {
      padding: 0px 5px;
      gap: 3px;
    }

    // img {
    //   width: 25px;

    //   @media (max-width: 576px) {
    //     width: 14px;
    //   }
    // }

    input[type="checkbox"] {
      position: absolute;
      left: -9999px;

      & + label {
        position: relative;
        display: inline-block;
        padding: 6px 10px 6px 6px;
        cursor: pointer;

        &:before {
          display: flex;
          align-items: center;
          justify-content: center;
          content: "+";
          position: absolute;
          right: -25px;
          top: 25%;
          margin: 9 0 0;
          width: 18px;
          height: 18px;
          background: #9da8b6;
          color: white;
          border-radius: 50%;
          font-size: 16px;
          font-weight: 700;

          @media (max-width: 576px) {
            width: 12px;
            height: 12px;
            font-size: 10px;
            right: -8px;
            top: 35%;
          }
        }
      }

      &:checked + label {
        &:before {
          content: "✔";
          background: #00d67d;
          width: 18px;
          height: 18px;
          font-size: 10px;

          @media (max-width: 576px) {
            width: 12px;
            height: 12px;
            font-size: 7px;
          }
        }
      }

      &:disabled {
        & + label {
          cursor: not-allowed;

          &:before {
            content: "-";
          }
        }
      }
    }
  }

  .facebookCheckbox {
    display: flex;
    align-items: center;
    gap: 15px;
    background: #f9fafc;
    border-radius: 10px;
    padding: 5px 15px;
    position: relative;

    @media (max-width: 576px) {
      padding: 0px 5px;
      gap: 3px;
    }

    // img {
    //   width: 25px;

    //   @media (max-width: 576px) {
    //     width: 14px;
    //   }
    // }

    input[type="checkbox"] {
      position: absolute;
      left: -9999px;

      & + label {
        position: relative;
        display: inline-block;
        padding: 6px 10px 6px 6px;
        cursor: pointer;

        &:before {
          display: flex;
          align-items: center;
          justify-content: center;
          content: "+";
          position: absolute;
          right: -30px;
          top: 25%;
          margin: 9 0 0;
          width: 18px;
          height: 18px;
          background: #9da8b6;
          color: white;
          border-radius: 50%;
          font-size: 16px;
          font-weight: 700;

          @media (max-width: 576px) {
            width: 12px;
            height: 12px;
            font-size: 10px;
            right: -10px;
            top: 35%;
          }
        }
      }

      &:checked + label {
        &:before {
          content: "✔";
          background: #00d67d;
          width: 18px;
          height: 18px;
          font-size: 10px;

          @media (max-width: 576px) {
            width: 12px;
            height: 12px;
            font-size: 7px;
          }
        }
      }

      &:disabled {
        & + label {
          cursor: not-allowed;

          &:before {
            content: "-";
          }
        }
      }
    }
  }

  .twitterCheckbox {
    display: flex;
    align-items: center;
    gap: 15px;
    background: #f9fafc;
    border-radius: 10px;
    padding: 5px 15px;
    position: relative;

    @media (max-width: 576px) {
      padding: 0px 5px;
      gap: 3px;
    }

    // img {
    //   width: 25px;

    //   @media (max-width: 576px) {
    //     width: 14px;
    //   }
    // }

    input[type="checkbox"] {
      position: absolute;
      left: -9999px;

      & + label {
        position: relative;
        display: inline-block;
        padding: 6px 10px 6px 6px;
        cursor: pointer;

        &:before {
          display: flex;
          align-items: center;
          justify-content: center;
          content: "+";
          position: absolute;
          right: -55px;
          top: 25%;
          margin: 9 0 0;
          width: 18px;
          height: 18px;
          background: #9da8b6;
          color: white;
          border-radius: 50%;
          font-size: 16px;
          font-weight: 700;

          @media (max-width: 576px) {
            width: 12px;
            height: 12px;
            font-size: 10px;
            right: -33px;
            top: 35%;
          }
        }
      }

      &:checked + label {
        &:before {
          content: "✔";
          background: #00d67d;
          width: 18px;
          height: 18px;
          font-size: 10px;

          @media (max-width: 576px) {
            width: 12px;
            height: 12px;
            font-size: 7px;
          }
        }
      }

      &:disabled {
        & + label {
          cursor: not-allowed;

          &:before {
            content: "-";
          }
        }
      }
    }
  }

  .linkedinCheckbox {
    display: flex;
    align-items: center;
    gap: 15px;
    background: #f9fafc;
    border-radius: 10px;
    padding: 5px 15px;
    position: relative;

    @media (max-width: 576px) {
      padding: 0px 5px;
      gap: 3px;
    }

    // img {
    //   width: 25px;

    //   @media (max-width: 576px) {
    //     width: 14px;
    //   }
    // }

    input[type="checkbox"] {
      position: absolute;
      left: -9999px;

      & + label {
        position: relative;
        display: inline-block;
        padding: 6px 10px 6px 6px;
        cursor: pointer;

        &:before {
          display: flex;
          align-items: center;
          justify-content: center;
          content: "+";
          position: absolute;
          right: -43px;
          top: 25%;
          margin: 9 0 0;
          width: 18px;
          height: 18px;
          background: #9da8b6;
          color: white;
          border-radius: 50%;
          font-size: 16px;
          font-weight: 700;

          @media (max-width: 576px) {
            width: 12px;
            height: 12px;
            font-size: 10px;
            right: -23px;
            top: 35%;
          }
        }
      }

      &:checked + label {
        &:before {
          content: "✔";
          background: #00d67d;
          width: 18px;
          height: 18px;
          font-size: 10px;

          @media (max-width: 576px) {
            width: 12px;
            height: 12px;
            font-size: 7px;
          }
        }
      }

      &:disabled {
        & + label {
          cursor: not-allowed;

          &:before {
            content: "-";
          }
        }
      }
    }
  }
}

.genericChannel {
  padding-bottom: 100px;

  .content {
    width: 500px;
    margin: auto;
  }

  @media (max-width: 576px) {
    width: 100%;
  }

  .defineCaptionWrapper {
    position: relative;

    .baseCaption {
      resize: none;
      border: 1px solid #c0ccda;
      border-radius: 20px;
      height: 200px;
      width: 100%;
      padding: 5px 20px;

      &:focus {
        outline: none;
      }
    }

    .baseCaptionSocialIcon {
      display: flex;
      text-align: center;
      justify-content: flex-end;
      margin-bottom: 10px;
    }

    .pickeremoji {
      position: absolute;
      right: -210px;

      @media (max-width: 576px) {
        right: 0px;
      }
    }
  }

  .postType {
    select {
      background-color: transparent;
      border: 1px solid #c0ccda;
      border-radius: 10px;
      padding: 8px 16px;
      width: 50%;
      &:focus {
        outline: none !important;
      }
    }
  }

  .fileupload {
    // width: 150px;
    background: #eff2f7;
    border-radius: 10px;
    position: relative;

    input[type="file"] {
      display: inline-block;
      opacity: 0;
      position: absolute;
      // margin-left: 20px;
      // margin-right: 20px;
      // padding-top: 30px;
      // padding-bottom: 67px;
      width: 85%;
      z-index: 99;
      margin-top: 10px;
      cursor: pointer;
    }

    .custom-file-upload {
      position: relative;
      display: inline-block;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 5px 20px;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      color: rgba(3, 32, 65, 0.8);

      @media (max-width: 576px) {
        padding: 5px 10px;
        gap: 5px;
        font-size: 12px;
      }

      div {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #4d81f6;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: white;

        @media (max-width: 576px) {
          font-size: 8px;
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

.uploadedImageHolder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  margin-bottom: 15px;

  .imageSize {
    display: flex;
    align-items: center;
    gap: 32px;
    @media (max-width: 576px) {
      gap: 8px;
    }

    .image {
      width: 55px;
      height: 55px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .imgsize {
      display: flex;
      flex-direction: column;

      p {
        margin: 0;
        padding: 0;
        margin-bottom: 5px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(17, 19, 23, 0.8);
        @media (max-width: 576px) {
          max-width: 200px;
        }
      }
    }
  }

  .delete {
    display: flex;
    gap: 5px;
    color: #ed2e7e;
    cursor: pointer;
    margin-top: 5px;

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      letter-spacing: 0.04px;
      text-decoration-line: underline;
    }
  }
}

.createPost {
  .content {
    width: 400px;
    margin: auto;
    padding: 50px 0px 120px 0px;
  }

  @media (max-width: 576px) {
    width: 100%;
  }

  .draftbtn {
    background: #ffffff;
    border: 1px solid #2062f4;
    font-size: 14px;
    color: #2062f4;

    @media (max-width: 576px) {
      font-size: 12px;
    }
  }

  .submitbtn {
    font-size: 14px;
    color: #f7f7fc;

    @media (max-width: 576px) {
      font-size: 12px;
      line-height: 15px;
    }
  }
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #6e7191 !important;
  //background-color: #fff;
  // border-color: none!important;
  // border: none!important;
  background: #e8f7ff !important;
  border: 1px solid #49a8f8 !important;
  border-radius: 10px;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: #fff !important;
  border: 1px solid rgb(241, 238, 238) !important;
  border-top-left-radius: none !important;
  border-top-right-radius: none !important;
  border-radius: 10px !important;
  width: 190px !important;
  @media (max-width: 576px) {
    width: 100% !important;
    // margin-right: 7px !important;
    // margin-left: 7px !important;
  }
}

.nav-link {
  display: block;
  padding: 0.2rem 1rem !important ;
  color: #6e7191 !important;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  @media (max-width: 576px) {
    font-size: 14px !important;
  }
}

.socialTab {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 1rem 0;
  cursor: pointer;
  img {
    width: 25px;
    display: flex;
    align-items: center;
  }
  p {
    margin: 0 !important;
    // display: flex;
    // align-items: center;
    // border: solid;
  }
}

.scheduleDateInput {
  background: #ffffff;
  border: 1px solid #49a8f8;
  border-radius: 16px;
  padding: 10px 20px;
  width: 100%;
  height: 64px;

  @include placeholder {
    font-size: 14px;
    color: #6e7191;
  }
}

.preview-modal {
  .pending {
    color: #ff2154;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    background-color: #e5ecf2;
    width: fit-content;
    padding: 6px 25px 6px 10px;

    div {
      background: #ff2154;
      width: 7px;
      height: 7px;
      border-radius: 50%;
    }
  }

  .published {
    color: #57d9a3;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    background-color: #e5ecf2;
    width: fit-content;
    padding: 6px 25px 6px 10px;

    div {
      background: #57d9a3;
      width: 7px;
      height: 7px;
      border-radius: 50%;
    }
  }

  .scheduled {
    color: #2062f4;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    background-color: #e5ecf2;
    width: fit-content;
    padding: 6px 25px 6px 10px;

    div {
      background: #2062f4;
      width: 7px;
      height: 7px;
      border-radius: 50%;
    }
  }

  .draft {
    color: #565c65;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    background-color: #e5ecf2;
    width: fit-content;
    padding: 6px 25px 6px 10px;

    div {
      background: #565c65;
      width: 7px;
      height: 7px;
      border-radius: 50%;
    }
  }
  .preview-post {
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding: 20px 0;

    @media screen and (max-width: 450px) {
      flex-direction: column;
    }
    img,
    video {
      width: 100%;
      height: 417px;
      object-fit: cover;

      @media screen and (max-width: 450px) {
        width: 100%;
      }
    }

    .post-caption {
      width: 40%;
      padding-top: 10px;
      h4 {
        font-size: 20px;
        opacity: 0.8;
        margin-bottom: 8px;
        background: linear-gradient(94deg, #49a8f8 6.88%, #0053f4 74.45%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.25px;
      }
      @media screen and (max-width: 450px) {
        width: 100%;
      }
    }
  }
}
