.container {
    padding: 20px 0

}

.title {
    margin-bottom: 50px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.36px;
    color: #000000;
}

.methods {
    margin-bottom: 50px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.36px;
    color: #000000;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

}

@media (min-width: 1600px) {
    .container {
        padding: 65px 60px 20px
    }
}
